'use strict';

var validator = require('../../validator'),
	forms = require('../../forms'),
	tabs = require('../../tabs'),
	ajax = require('../../ajax'),
	progress = require('../../progress'),
	page = require('../../page'),
	util = require('../../util');

var handlers = { 
	loginSubmit: function() {
		$('.account-modal').on('click touchstart', '.account-login-submit', function(e) { 
			e.preventDefault();
			$('.error-form').hide();
			var $curForm = $(this).closest('form');
			$curForm.validate();
			if (!$curForm.valid()) {
				return false;
			}
			progress.show($curForm);

			ajax.load({
				url: util.appendParamToURL($curForm.attr('action'), 'viewType', 'modal'),
				type: 'POST',
				data: $curForm.serialize(),
				callback: function(data) {
					var json = util.isJson(data);
					var $data = $('<div>').append($.parseHTML(data));
					if (json && json.redirectUrl && json.loginPage === 'postPurchase') {
						window.location.href = json.redirectUrl;
					} else if ($data.find('.error-form').length > 0) {
						// Error
						$curForm.closest('.account-login-register').html($($data));
						$('.account-modal').animate({ scrollTop: '0' });
						init.form();
					} else if ($data.find('.user-shopping-terms').length > 0) {
						// Employee
						var modalClass = 'user-shopping-terms-toaster'
						var $userTerms = $data.find('.user-shopping-terms');
						modal.close($('.account-login-register-modal'));
						modal.open($userTerms, modalClass);
						handlers.userAgreement();
					} else if ($data.find('#wishlist-movelist-form').length > 0) {
						// Add to Wishlist
						handlers.addToWishlistLogin($data);
					} else if (json && json.page === 'dpidwardrobe') {
						util.updateDpidWardrobe(json,page,modal);
						return;
					} else {
						// Success
						handlers.setLoginLocation($curForm);
						page.refresh();
					}
				}
			});
		});
	},

	registerSubmit: function() {
		$('.account-modal').on('click touchstart', '.account-register-submit', function(e) { 
			e.preventDefault();
			var $curForm = $(this).closest('form');
			$curForm.validate();
			if (!$curForm.valid()) {
				return false;
			}
			progress.show($curForm);

			var url = util.appendParamToURL($curForm.attr('action'), $(this).attr('name'), true);
				url = util.appendParamsToUrl(url, {
					'format': 'ajax',
					'viewType': 'modal'
				});

			ajax.load({
				url: url,
				type: 'POST',
				data: $curForm.serialize(),
				callback: function(data) {
					var json = util.isJson(data);
					var $data = $('<div>').append($.parseHTML(data));

					if ($data.find('.account-login-register').length > 0) {
						$curForm.closest('.account-login-register').html($($data));
						$('.account-modal').animate({ scrollTop: '0' });
						init.form();
						progress.hide();
					} else if ($data.find('#wishlist-movelist-form').length > 0) {
						// Add to Wishlist
						handlers.addToWishlistLogin($data);
					} else if (json && json.page === 'dpidwardrobe') {
						
						util.updateDpidWardrobe(json,page,modal);
						return;
					} else {
						handlers.setLoginLocation($curForm);
					}
				}
			});
		});
	},

	userAgreement: function() {
		$('.user-shopping-terms').on('click touchstart', 'button.accept-button, button.decline-button', function(e) {
			e.preventDefault();
			var $curForm = $(this).closest('form');
			progress.show($(this));

			ajax.load({
				url: $curForm.attr('action'),
				type: 'POST',
				data: $curForm.serialize(),
				callback: function(data) {
					var $data = $('<div>').append($.parseHTML(data));
					if ($data.find('#wishlist-movelist-form').length > 0) {
						// Add to Wishlist
						handlers.addToWishlistLogin($data);
					} else {
						page.refresh();
					}
				}
			});
		});
		setTimeout(function() {
			$('.account-modal-mask').off('click');
		}, 500);
	},

	resetPassword: function() {
		$('.account-login-register .login-passwordreset a').on('click touchstart', function(e) {
			e.preventDefault();

			ajax.load({
				url: $(e.target).attr('href'),
				type: 'GET',
				async: false,
				callback: function(data) {
					var modalClass = 'account-register-reset-password';
					var $data = $('<div>').append($.parseHTML(data));
					modal.open($data, modalClass);
					$('.' + modalClass).parent('.account-modal-container').find('.account-modal-mask').css('background', 'tansparent');
					$('.' + modalClass).css('height', $('.account-login-register-modal').outerHeight());
					init.form();

					// Email not found - close modal
					$('.' + modalClass).find('.register-message').on('click touchstart', function(e) {
						modal.close($('.' + modalClass));
						$('.account-login-register #createlogin-tab').trigger('click');
					});

					handlers.resetPasswordResponse();
				}
			});

			require('../../aepdatacollection-util').coreCall('forgotPassword');
		});
	},

	resetPasswordResponse: function() {
		var $formBtn = $('#PasswordResetForm button');

		$formBtn.on('click touchstart', function (e) {
			e.preventDefault();
			var $curForm = $(this).closest('form');
			$curForm.validate();
			if (!$curForm.valid()) {
				return false;
			}

			var $data = $curForm.serialize();
			$data += '&' + $formBtn.attr('name') + '=';
			if ($data.indexOf('ajax') === -1) {
				$data += '&format=ajax';
			}

			ajax.load({
				url: $curForm.attr('action'),
				type: 'POST',
				data: $data,
				callback: function(data) {
					var $data = $('<div>').append($.parseHTML(data));
					if ($data.find('.password-request-confirm-container').length > 0) {
						$curForm.closest('.password-request-modal-container').html($($data));
					} else {
						$curForm.closest('.password-request-modal-container').replaceWith($($data));
						init.form();
						handlers.resetPasswordResponse();
					}
				}
			});
		});
	},

	addToWishlistLogin: function($data) {
		modal.close($('.account-modal'));

		var wishlist = require('../wishlist');
		var pid = $data.find('.add-to-favorites').attr('productid');
		var $element = $('.add-to-favorites[productid="' + pid + '"]');
	
		$('.wl-confirm-modal .ui-dialog-titlebar-close').trigger('click');
	
		$.ajax({
			url: Urls.HTMLHeader,
			success: function(data) {
				$('.header-acc').html(data);
			}
		});
	
		wishlist.showConfirmationMsg($element, $data);
	},

	setLoginLocation: function ($curForm) {
		if (!$curForm.is(":empty") && $curForm[0].action.indexOf('LoginLocation') !== -1) {
			var param = $curForm.attr('action').split('?')[1];
			var redirecturl = window.location.href + ((window.location.href.indexOf('?') > 0) ? '&' : '?') + param;
			page.redirect(redirecturl);
		} else {
			page.refresh();
		}
	}
};


var formEvents = {
	checkBoxes: function() {
		// Remember Me
		$('input[name$="login_rememberme"]').on('change', function() {
			var form = $(this).closest('form');
			var formParent = $(form).parent().parent();

			if ($(this).is(':checked')) {
				formParent.find('#oauthrememberme').val('true');
				formParent.find('.rememberme input[type="checkbox"]').val('true');
			} else {
				formParent.find('#oauthrememberme').val('false');
				formParent.find('.rememberme input[type="checkbox"]').val('false');
			}
		});

		// Email Subscribe
		$('input[name$="profile_customer_addtoemaillist"]').on('change', function() {
			var form = $(this).closest('form');
			var formParent = $(form).parent().parent();

			if ($(this).is(':checked')) {
				formParent.find('#oauthaddtoemaillist').val('true');
			} else {
				formParent.find('#oauthaddtoemaillist').val('false');
			}
		});
	},

	socialIcons: function() {
		$('.oAuthIcon').on('click touchstart', function() {
			var form = $(this).closest('form');
			form.find('#OAuthProvider').val(this.id);
		});
	},

	privacyPolicy: function() {
		$('.account-login-register .privacy-policy').on('click touchstart', function(e) {
			e.preventDefault();

			ajax.load({
				url: $(e.target).attr('href'),
				type: 'GET',
				async: false,
				callback: function(data) {
					var modalClass = 'account-regiter-privacy-policy';
					var $data = $('<div>').append($.parseHTML(data));
					modal.open($data, modalClass);
				}
			});
		});
	}
};


var modal = {
	open: function($data, modalClass) {
		var $thisModal = modal.create(modalClass);
		var $overlay = $('<div/>').prop('class', 'account-modal-mask').appendTo($thisModal);
		$data.appendTo('.' + modalClass);
		$('body').addClass('prevent-scroll');

		// Load modal before animating & init
		setTimeout(function() {
			if ($('.account-modal-mask').length > 1) {
				$thisModal.find('.account-modal-mask').addClass('transparent');
			}
			$thisModal.find('.account-modal').addClass('opened');
			$overlay.addClass('opened').on('click touchstart', function() { modal.close($thisModal)});
			$thisModal.on('click touchstart', '.account-modal-close', function() { modal.close($thisModal)});
		}, 500);
	},

	close: function($el) {
		var $thisModal = $el.closest('.account-modal-container');
		$thisModal.children('.opened').removeClass('opened');

		// Completly close modal before removing
		setTimeout(function() {
			$thisModal.remove();

			if ($('.account-modal-container').length <= 0) {
				$('body').removeClass('prevent-scroll');
			}
		}, 500);
	},

	create: function(modalClass) {
		var $modalContainer = $('<div/>').prop('class', 'account-modal-container enhanced-signin ui-dialog').prop('tabindex', '-1').prop('role', 'dialog').appendTo($('body'));
		var $modalContent = $('<div/>').prop('class', 'account-modal').appendTo($modalContainer);
		$('<button/>').prop('class', 'account-modal-close').prop('type', 'button').prop('title', 'close').appendTo($modalContent);

		if (modalClass) {
			$modalContainer.find('.account-modal').addClass(modalClass);
		}

		return $modalContainer;
	},

	isValid: function(data, modalClass) {
		var validData = false;
		var $data = $('<div>').append($.parseHTML(data)).find('.account-login-register');
		if ($data.length > 0 && typeof $data === 'object') {
			modal.open($data, modalClass); 
			init.form();
			
			var validData = true;
		}
		return validData;
	}
};

var init = {
	form: function() {
		tabs.init();
		forms.init();
		validator.init();
		handlers.loginSubmit();
		handlers.registerSubmit();
		handlers.resetPassword();
		validator.passwordConstraints();
		formEvents.privacyPolicy();
		formEvents.checkBoxes();
		formEvents.socialIcons();
	}
};


module.exports = {
	handlers: handlers,
	formEvents: formEvents,
	modal: modal,
	init: init
};