'use strict';

function accountMenuToggle() {
	var accountMenu = $('.registered-nav-list').prop('aria-hidden', true);
	var accountBtn = $('.account-nav-registered h3').attr('role', 'button').prop('aria-expanded', false);
	accountBtn.on('click',function() {
		if($(window).width() < 960) {
			accountMenu.slideToggle();
			$('.account-nav-registered').find('h3').toggleClass('rotate');
		}
    });
	var desk = $(window).width() > 960;
	$(window).on('resize',function() {
		if($(window).width() > 960) {
			desk = true;
			$('.registered-nav-list').css('display', 'block');			
		} else {
			if(desk){				
				$('.registered-nav-list').css('display', 'none');
			    $('.account-nav-registered h3').removeClass('rotate');
			}
			desk = false;
		}
	});
}

exports.accountMenuToggle = accountMenuToggle;