function AsyncRunner() {
    var fired = [];
    var tasks = {};

    this.put = function (category, f) {
        if (hasFired(category)) {
            run(f);
        } else {
            tasks[category] = tasks[category] || [];
            tasks[category].push(f);
        }
        return this; // helps chaining
    };

    this.fire = function (category) {
        markFired(category);
        var t = getTasks(category);
        while (t.length) {
            run(t.shift());
        }
    };

    function markFired(category) {
        if (!hasFired(category)) {
            fired.push(category);
        }
    }

    function hasFired(category) {
        return fired.indexOf(category) >= 0;
    }

    function getTasks(category) {
        return tasks[category] || [];
    }
    
    function run(f) {
        try {
            f();
        } catch (e) {
            // Leaving this in to be kind to posterity.
        }
    }
    
}

// Implements a singleton pattern to ensure each page just has one of these.
// Otherwise, Bad Things will happen.
module.exports = {
    getInstance: (function () {
        var runner;
        return function () {
            if (!runner) {
                runner = new AsyncRunner();
            }
            return runner;
        };
    }())
};