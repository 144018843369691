'use strict';
// For easy reference
var keys = {
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    delete: 46,
    enter: 13,
    space: 32
};

// Add or subtract depending on key pressed
var direction = {
    37: -1,
    38: -1,
    39: 1,
    40: 1
};

function addListeners (index, tabs) {
    /*tabs[index].removeEventListener('click');
    tabs[index].removeEventListener('keydown'); 
    tabs[index].removeEventListener('keyup'); */
    //
    tabs[index].addEventListener('click', function(event){
        clickEventListener(event, tabs);
    }, false);
    tabs[index].addEventListener('keydown', function(event){
        keydownEventListener(event, tabs);
    }, false); 
    tabs[index].addEventListener('keyup', function(event){
        keyupEventListener(event, tabs);
    }, false);  
    tabs[index].index = index;
};

function focusFirstTab($tabsWrapper) {
    activateTab($tabsWrapper.find('> [role="tablist"] [role = "tab"]')[0]);
};

function focusLastTab($tabsWrapper) {

    activateTab($tabsWrapper.find('> [role="tablist"] [role = "tab"]').last()[0]);
};

function clickEventListener(event, tabs) {
    event.preventDefault();
    var tab = event.target;
    activateTab(tab);
};

function keydownEventListener(event, tabs) {
    var key = event.keyCode;
    var tab = event.target;
    var $tabsWrapper = $(tab).closest('.tabs-wrapper');

    switch (key) {
        case keys.end:
            event.preventDefault();
            // Activate last tab
            focusLastTab($tabsWrapper, tabs);
            break;
        case keys.home:
            event.preventDefault();
            focusFirstTab($tabsWrapper, tabs);
            break;
    };
};

// Handle keyup on tabs
function keyupEventListener(event, tabs) {
    var key = event.keyCode;

    switch (key) {
        case keys.left:
        case keys.right:
            switchTabOnArrowPress(event, tabs);
            break;
        case keys.enter:
        case keys.space:
            activateTab(event.target, tabs);
        break;    
    };
};

// Either focus the next, previous, first, or last tab
// depending on key pressed
function switchTabOnArrowPress (event, tabs) {
var pressed = event.keyCode;

    if (direction[pressed]) {
        var target = event.target;
        if (target.index !== undefined) {
            if (tabs[target.index + direction[pressed]]) {
                tabs[target.index + direction[pressed]].focus();
            } else if (pressed === keys.left || pressed === keys.up) {
                focusLastTab();
            } else if (pressed === keys.right || pressed == keys.down) {
                focusFirstTab();
            }
        }
    }
};


// Activates any given tab panel
function activateTab(tab) {
    var $tabsWrapper = $(tab).closest('.tabs-wrapper');
    deactivateTabs($tabsWrapper);
    tab.removeAttribute('tabindex');
    tab.setAttribute('aria-selected', 'true');
    var controls = tab.getAttribute('aria-controls');
    if($tabsWrapper.data('tab-event')) {
        // listener is responsible for setting aria values
        tab.dispatchEvent(new CustomEvent($tabsWrapper.data('tab-event'), {bubbles: true, detail: {controls: $tabsWrapper.find('#' + controls)[0]}}));
    } else {
        $tabsWrapper.find('#' + controls).removeAttr('hidden').attr('aria-hidden', 'false').show();
    }
    tab.focus();
    
    
    //for login page
    if ($('.col-2.benefits').length && !$('.account-login-dialog').length ) { 

        if (tab.id == 'login-tab') {
            $('.col-2.benefits').hide();
            $('.col-2.order-track').show();
        } else if (tab.id == 'createlogin-tab') {
            $('.col-2.benefits').show();
            $('.col-2.order-track').hide();

        }
    }
};

// Deactivate all tabs and tab panels
function deactivateTabs($tabsWrapper) {
    if ($tabsWrapper) {
        $tabsWrapper.find('> [role="tablist"] [role = "tab"]').attr('tabindex', '-1').attr('aria-selected', 'false').each(function () {
            if (!$tabsWrapper.data('tab-event')) {
                var $controls = $tabsWrapper.find('#' + this.getAttribute('aria-controls'));
                $controls.attr('hidden', 'hidden').attr('aria-hidden', 'true').hide();
            }
        });
        if (!$tabsWrapper.data('tab-event')) {
            $tabsWrapper.find('.controlled-content').attr('hidden', 'hidden').attr('aria-hidden', 'true').hide();
        }

    }
};

// tablist NEEDS to be a direct child of .tabs-wrapper 
function initializeEvents($wrapper) {
    $('.tabs-wrapper > [role="tablist"]', $wrapper).each(function (i) {
       // remove old listeners
       var wrapper = this.cloneNode(true);
       this.replaceWith(wrapper);
       //
        var tabs = $(wrapper).find('[role="tab"]').toArray();
        // Bind listeners
        for (var i = 0; i < tabs.length; ++i) {
            addListeners(i, tabs);
        };
    }); 

};

exports.init = function($wrapper) {
    initializeEvents($wrapper);
};
exports.focus = function(tab, $wrapper) {
    if(tab){
        activateTab(tab);
    } else if( $wrapper ){
        deactivateTabs($wrapper)
    }
    
};
