'use strict';

function getOffset(arrow, target) {
    var arrowMiddle = (arrow.left + arrow.right) / 2;
    var targetMiddle = (target.left + target.right) / 2;
    var offset = targetMiddle - arrowMiddle + 2;
    return offset;
}

function onTooltipClick(e) {
    e.preventDefault();
    e.stopPropagation();
}

function onTooltipHover() {
    $('.rl-tooltip').attr('tabindex', '0');
}

/* Ensure that the arrow at the bottom of the tooltip points to the target of the tooltip.
    */
function adjustArrowPlacement(event, ui) {
    try {
        var $arrow = ui.tooltip.find('.arrow');
        var target = event.originalEvent.target;
        var offset = getOffset($arrow[0].getBoundingClientRect(), target.getBoundingClientRect());
        var left = parseInt($arrow.css('left'), 10) || 0; // default to 0 if NaN.
        $arrow.css('left', left + offset);
    } catch (ignored) {
        // Nothing we can do, but don't break anything.
    }
}

function spanClickEvent(event) {
    try {
        var t = event.originalEvent.target;

        if (($(t).hasClass('swatchanchor') || $(t).hasClass('attribute-value')) && ($(window).width() < 960) && !$(t).closest('li').hasClass('selected')) {
            $(t).closest('.swatchanchor').trigger('click');
        }
    } catch (ignored) {
        //
    }
}

function onShareLinkClick(e) {
    e.preventDefault();
    var target = $(this).data('target');
    if (!target) {
        return;
    }
    $(target).toggleClass('active');
}

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    try {
        $(document).tooltip('destroy');
    } catch (e) {
        // Nothing to destroy
    }

    $(document).tooltip({
        items: '.tooltip',
        show: 200,
        hide: 200,
        position: {
            my: 'center bottom-23',
            at: 'center bottom-23',
            collision: 'flipfit flip',
            using: function(position, feedback) {
                let adjustmentValue = 0;

                if (this.querySelector('.tt-up')) {
                    adjustmentValue = 20;
                } else if (this.querySelector('.js-tt-redesign')) {
                    adjustmentValue = parseInt(this.querySelector('.js-tt-redesign').dataset.dist || -10, 10);
                }

                if (feedback.vertical === 'top') {
                    position.top += adjustmentValue;
                } else {
                    position.top -= adjustmentValue;
                }

                $(this).css(position);
                $('<div>')
                    .addClass('arrow')
                    .addClass(feedback.vertical)
                    .addClass(feedback.horizontal)
                    .appendTo(this);
            }
        },
        tooltipClass: 'rl-tooltip',
        content: function () {
            if ($(this).data('aid')) {
                return $('#' + $(this).data('aid')).html();
            } else {
                return $(this).find('.tooltip-content').html();
            }
        },
        create: function () {
            $(this).find('.tooltip[data-aid]').each(function () {
                const content = document.getElementById(this.dataset.aid);
                if (!(content && content.textContent.replace(/\n|\r|\s/gm, '') !== '')) {
                    this.remove();
                } else {
                    this.classList.add('validated');
                }
            });
        },
        open: function (event, ui) {
            adjustArrowPlacement(event, ui);
            spanClickEvent(event, ui);
            var $element = $(event.target);
            ui.tooltip.on('click', function () {
                $element.tooltip('close');
            });
        },
        close: function(event, ui) {
            ui.tooltip.hover(function() {
                $(this).stop(true).fadeTo(100, 1);
            },
            function() {
                $(this).fadeOut('300', function() {
                    $(this).remove();
                });
            });
        }
    });

    $('.sameday').tooltip({
        items: '.js-tooltip-bottom',
        show: 300,
        position: {
            my: 'center top+15',
            at: 'center top+15',
            collision: 'flipfit flip',
            using: function(position, feedback) {
                $(this).css(position);
                $('<div>')
                    .addClass('arrow')
                    .addClass(feedback.vertical)
                    .addClass(feedback.horizontal)
                    .appendTo(this);
            }
        },
        tooltipClass: 'rl-tooltip',
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        open: function (event, ui) {
            adjustArrowPlacement(event, ui);
            var $element = $(event.target);
            ui.tooltip.on('click', function () {
                $element.tooltip('close');
            });
        },
        close: function(event, ui) {
            ui.tooltip.hover(function() {
                $(this).stop(true).fadeTo(100, 1);
            },
            function() {
                $(this).fadeOut('300', function() {
                    $(this).remove();
                });
            });
        }
    });

    $('.returns-ship-option').tooltip({
        items: '.js-tooltip-bottom',
        show: 300,
        position: {
            my: 'center bottom-15',
            at: 'center bottom-15',
            collision: 'flipfit flip',
            using: function(position, feedback) {
                $(this).css(position);
                $('<div>')
                    .addClass('arrow')
                    .addClass(feedback.vertical)
                    .addClass(feedback.horizontal)
                    .appendTo(this);
            }
        },
        tooltipClass: 'rl-tooltip',
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        open: function (event, ui) {
            adjustArrowPlacement(event, ui);
            var $element = $(event.target);
            ui.tooltip.on('click', function () {
                $element.tooltip('close');
            });
        },
        close: function(event, ui) {
            ui.tooltip.hover(function() {
                $(this).stop(true).fadeTo(100, 1);
            },
            function() {
                $(this).fadeOut('300', function() {
                    $(this).remove();
                });
            });
        }
    });

    $('.label').tooltip({
        items: '.js-tooltip-bottom',
        show: 300,
        position: {
            my: 'center top+15',
            at: 'center top+15',
            collision: 'flipfit flip',
            using: function(position, feedback) {
                $(this).css(position);
                $('<div>')
                    .addClass('arrow')
                    .addClass(feedback.vertical)
                    .addClass(feedback.horizontal)
                    .appendTo(this);
            }
        },
        tooltipClass: 'rl-tooltip',
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        open: function (event, ui) {
            adjustArrowPlacement(event, ui);
            var $element = $(event.target);
            ui.tooltip.on('click', function () {
                $element.tooltip('close');
            });
        },
        close: function(event, ui) {
            ui.tooltip.hover(function() {
                $(this).stop(true).fadeTo(100, 1);
            },
            function() {
                $(this).fadeOut('300', function() {
                    $(this).remove();
                });
            });
        }
    });

    $('.share-link').off('click', onShareLinkClick).on('click', onShareLinkClick);

    /*JIRA PREV-282 : DEV-32: SG issue- 'What is this' link in checkout billing page should not be clickable. Added the following block.*/
    $(document).off('click', 'a.tooltip, a.js-tooltip-bottom', onTooltipClick).on('click', 'a.tooltip, a.js-tooltip-bottom', onTooltipClick);

    $(document).off('hover mouseenter', 'a.tooltip, a.js-tooltip-bottom', onTooltipHover).on('hover mouseenter', 'a.tooltip, a.js-tooltip-bottom', onTooltipHover);
};
