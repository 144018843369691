'use strict';

var imagesLoaded = require('imagesloaded'),
    rating = require('./rating'),
    util = require('./util'),
    quickview = require('./quickview'),
    productTileLazyLoad = require('./components/productTile/productTileLazyLoad'),
    productTiles = require('./pages/plp/product-tiles'),
    Replacer = require('./pages/Replacer'),
    aep = require('./aepdatacollection-util');


var initialized = false;
var productTileObserver;

function initQuickViewButtons() {
    $('.tiles-container .product-image').on('mouseenter', function() {
        var $qvButton = $('#quickviewbutton');

        if ($qvButton.length === 0) {
            $qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '<i class="fa fa-arrows-alt"></i></a>');
        }

        var $link = $(this).find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('href'),
            'title': $link.attr('title')
        }).appendTo(this);

        $qvButton.off('click').on('click', function(e) {
            e.preventDefault();

            var position = $('li.grid-tile').index($(this).closest('li.grid-tile'));

            quickview.show({
                url: $(this).attr('href').split('#')[0], //PREV JIRA PREV-255 :PLP: On Click Quick view navigating to a wrong page when user first changes the swatches. Taking only href.
                source: 'quickview',
                position: position
            });

            if (isGoogleAnalyticsEnabled) {
                require('./ga').quickview($(this)[0].pathname);
            }

            if (SitePreferences.ANALYTICS_ENABLED != undefined && SitePreferences.ANALYTICS_ENABLED) {
                setTimeout(function() {
                    $('#s7viewer_swatches div.s7thumb').off('click').on('click', function() {
                        aep.coreCall('captureClickEvent', 'altImageClick', 'product');
                    });
                }, 3000);
            }
        });
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    if (SitePreferences.QUICK_VIEW_ENABLED) {
        initQuickViewButtons();
    }

    rating.init(); //PREVAIL-Added to handle ratings display in-case of ajax calls.

    var $container = $('#primary');

    $container.on('click', '.swatch-list .swatch', function(e) {
        e.preventDefault();

        var urlHref = $(this).attr('href');
        var $swatchLink = $(this);
        var $currentTile = $(this).closest('.js-product-tile');
        var masterId = $currentTile.data('masterid');
        urlHref = urlHref.split('#')[0];
        var params = {
            format: 'ajax',
            showswatches: $currentTile.attr('data-showswatches'),
            showpricing: $currentTile.attr('data-showpricing'),
            showpromotion: $currentTile.attr('data-showpromotion'),
            showrating: $currentTile.attr('data-showrating'),
            tiletype: $currentTile.attr('data-tiletype'),
            pricerange: true
        };

        var $wishlistTileBtn = $currentTile.find('.plp-favorites');

        // prevent clicks on add-to/remove-from WL button while tile is updating
        $wishlistTileBtn.css('pointer-events', 'none');

        $.ajax({
            type: 'GET',
            url: util.appendParamsToUrl(urlHref, params),
            success: function(data) {
                var $data = $('<div>').append($.parseHTML(data));

                // destroy old swiper
                productTiles.destroyProductTileSwiper($currentTile);

                const productTileReplacer = new Replacer($currentTile, $data);
                productTileReplacer.replace('.product-image');
                productTileReplacer.replace('.product-data > .product-tile-quick-shop');
                productTileReplacer.replace('.product-data-swatches');

                // init new swiper
                productTiles.initProductTileSwiper('.js-product-tile-swiper[data-initialized="false"]', $currentTile);

                if (SitePreferences.QUICK_VIEW_ENABLED) {
                    initQuickViewButtons();
                }

                if (SitePreferences.QUICK_SHOP_ENABLED) {
                    document.dispatchEvent(new CustomEvent('addQuickShop', {bubbles: true, detail: {checkProducts: false, type: 'link'}}));
                }

                aep.searchCall('plpSwatchClick', $swatchLink, masterId);
            },
            complete: function() {
                // unlock add-to/remove-from WL button in case it wasn't modified or request error
                $wishlistTileBtn.css('pointer-events', '');
            }
        });
    });

    $container.on('mouseenter', '.product-image', function() {
        productTileLazyLoad.lazyLoadVideos(this, true);

        $('.fading-in', $(this)).addClass('display');

        $('.plp-favorites.fading-in', $(this)).on('click', '.save-To-favorites', function(e) {
            $(this).off(e);
            $(this).closest('.plp-favorites.fading-in').addClass('keep').one('click', function() {
                $(this).removeClass('display keep');
            });
        });
    }).on('mouseleave', '.product-image', function() {
        $('.fading-in', $(this)).each(function() {
            if (!$(this).hasClass('keep')) {
                $(this).removeClass('display keep');
            }
        });

        $('.plp-favorites.fading-in', $(this)).off('click', '.save-To-favorites');
    });
}

exports.init = function() {
    if (!initialized) {
        initialized = true;
        imagesLoaded('.tiles-container');
        productTileObserver = productTileLazyLoad.initProductTileIntersectionObserver();
        initializeEvents();
    }

    // init all swiper carousels
    productTiles.initProductTileSwiper('.js-product-tile-swiper[data-initialized="false"]');


    if (productTileObserver) {
        productTileLazyLoad.addElementsToObserver(document.querySelector('#primary'), productTileObserver);
    }
};
