'use strict';

const _debounce = require('lodash/debounce');
const util = require('../util');
const {gsap}  = require('gsap');

let productTileHeightListener = false;
let productTileHeight = 200;

function watchNewRlcBlocks() {
    try {
        window._rlc.inView(); // adds all .rlc-block elements to the "inView" watcher
        window._rlc.lazyLoader(); // adds "inView" listener to lazy load images
    } catch (error) {
        // continue regardless of error
    }
}

function emptyProduct() {
    $('ul.search-result-items li.plp-custom-image-section').each(function () {
        var $this = $(this);

        if ($this.children().length === 0) {
            $this.remove();
        }
    });
}

function filterControlSwitch() {
    var filterLabelHide = $('.filter-control .filter-hide');
    var filterLabelShow = $('.filter-control .filter-show');
    var secondaryNav = $('#secondary');

    if (secondaryNav.hasClass('hide')) {
        filterLabelHide.hide();
        filterLabelShow.show();
    } else {
        filterLabelHide.show();
        filterLabelShow.hide();
    }
}

//Expand the first refinement if mobile. Remember to hide the collapsed lists as well so animation works on the first click.
function resetRefinement() {
    var $refinementBar = $('#refinments-bar');
    var $filterElement = $('.left-search-options');

    if ($refinementBar.length > 0 && $filterElement.length > 0) {
        var $sectionHeading = $('.secondary-refinements .section-heading');

        $sectionHeading.removeClass('clicked');
        $sectionHeading.removeClass('clicked-no-animation');
        $sectionHeading.addClass('closed');
        $sectionHeading.parent().find('ul').css('display','none');
    }
}

function priceLabelPosition(create) {
    var min = $('.ui-slider-handle').first().css('left');
    var minWidth = ($('.min-price-lbl').width() || $('.min-price-lbl').text().length * 9) + 10;
    var max = $('.ui-slider-handle').last().css('left');
    var space = 0;

    if (typeof max === 'undefined') {
        return false;
    }

    // convert percentage values into px
    if (max.indexOf('%') !== -1) {
        var slidereWidth = $('.price-refinments').width() - 40;

        if (!$('.price-refinments').width() && !$('#secondary').is(':visible')) {
            slidereWidth = $('.price-refinments').width() - 32;
        }

        min = Number(min.replace('%', '')) * slidereWidth / 100;
        max = Number(max.replace('%', '')) * slidereWidth / 100;
        space = max - min;
    } else {
        min = Number(min.replace('px', ''));
        max = Number(max.replace('px', ''));
        space = max - min;
    }

    // check if the two labels are too close to each other
    if (space > minWidth) {
        $('.min-price-lbl').css({'left': min + 'px'});
        $('.max-price-lbl').css({'left': max + 'px'});
    } else if (create) {
        if (space < minWidth) {
            min -= minWidth;
        }

        $('.min-price-lbl').css({'left': min + 'px'});
        $('.max-price-lbl').css({'left': max + 'px'});
    }

    // Handling the negative scenario when the min and max values gives negative
    if (min < 0 || max < 0) {
        $('.min-price-lbl').css({'left': '0'});
        $('.max-price-lbl').css({'left': '100%'});
    }
}

function findTileInList(url, $pid) {
    // this code is NEVER executed as the calling line is never reached in above updateProductWindowback
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');
    var lastPlpScrollPosition = history && history.state  && 'plpScrollPosition' in history.state ? history.state.plpScrollPosition : null;
    var productImgHeight = sessionStorage['plp-product-img-height'];

    if (typeof sessionStorage['scroll-cache_' + gridUrl] === 'undefined' || loadingPlaceHolder.length === 0) { return false; }

    loadingPlaceHolder.removeClass('infinite-scroll-loading');
    loadingPlaceHolder.attr('data-loading-state', 'loaded');

    $('#primary div.search-result-content').append(sessionStorage['scroll-cache_' + gridUrl]);

    document.dispatchEvent(new Event('productTilesUpdated'));

    $('.product-tile .product-image').css('min-height', productImgHeight); // giving height from session value, Now it will consider this new height if product images not loaded also

    $('html, body').animate({
        scrollTop: lastPlpScrollPosition // It will scroll to exact product row
    }, 100);
}

//back-button PDPtoPLP
function storePlpScrollPosition($target, windowScrollTop) {
    // var a = $(this);

    // get current page refinement values
    var wl = window.location;

    var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
    var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

    // merge hash params with querystring params
    var params = $.extend(hashParams, qsParams);

    if (!params.start) {
        params.start = 0;
    }

    var tile;
    if ($target.closest('.js-redesign-qs-toaster').length) {
        tile = $(`.product-tile[data-masterid=${$target.closest('.js-redesign-qs-toaster').find('input#MasterID').val()}]`);
    } else {
        tile = $target.closest('.product-tile');
    }
    // get the index of the selected item and save as start parameter
    //var tile = $target.closest('.product-tile');
    var idx = tile.data('idx') ? +tile.data('idx') : 0;

    /*Start JIRA PREV-50 : Next and Previous links will not be displayed on PDP if user navigate from Quick View.Added cgid to hash*/
    if (!params.cgid && tile.data('cgid') !== null && tile.data('cgid') !== '') {
        params.cgid = tile.data('cgid');
    }
    /*End JIRA PREV-50*/

    // convert params.start to integer and add index
    params.start = (+params.start) + (idx + 1);

    // set the hash and allow normal action to continue
    $target[0].hash = $.param(params);

    // save scroll position
    const historyStateData = {
        plpScrollPosition: windowScrollTop || $(window).scrollTop()
    };

    if (tile.length && tile.attr('id')) {
        historyStateData.pid = tile.attr('id');
    }

    if (history.scrollRestoration && SitePreferences.LISTING_INFINITE_SCROLL) {
        history.scrollRestoration = 'manual';

        const $tileContainer = tile.closest('.js-paginated-container[data-view-all]');

        if ($tileContainer.length) {
            historyStateData.viewAllActive = $tileContainer.data('container-uuid');
        }
    }

    history.replaceState(historyStateData, '', window.location.href);

    sessionStorage.setItem('lastvisitedpage', $('.infinite-scroll-placeholder').first().data('grid-url'));
    sessionStorage.setItem('lastvisitedproduct', $target.closest('.product-tile').data('itemid'));
    sessionStorage.setItem('plp-product-img-height', $('.product-tile .product-image').height());
}

function initBackButtonforQS(e) {
    var scrollTop;
    var $target = $(e.target);
    function storePLPForBackButton() {
        storePlpScrollPosition($target, scrollTop);
    }
    function closeOrNavigateFromQS() {
        document.removeEventListener('onFullDetailsClick', storePLPForBackButton);
    }
    document.addEventListener('toaster:opened', function (e) {
        scrollTop = e.detail.marginTop;
    }, {
        once: true
    });
    document.addEventListener('toaster:close', closeOrNavigateFromQS, {
        once: true
    });
    document.addEventListener('onFullDetailsClick', storePLPForBackButton);
}

function initQS() {
    $('.js-qs-cta:not(.bb-init)').on('click', function (e) {
        initBackButtonforQS(e);
    }).addClass('bb-init');
}

function updateProductWindowBack() {
    var $url = sessionStorage['lastvisitedpage'];
    var $pid = sessionStorage['lastvisitedproduct'];
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (($('.infinite-scroll-placeholder[data-grid-url="' + $url + '"]').length === 0) && $url !== '' && $pid !== '') { return false; }

    // this code is NEVER executed as .infinite-scroll-placeholder isn't in any NA or EU template
    if ($('.product-tile[data-itemid="' + $pid + '"]').length === 0) {
        findTileInList(gridUrl, $pid);
    }
}

function prefetch() {
    if (navigator.serviceWorker && SitePreferences.SERVICE_WORKER_ENABLED) {
        if (!navigator.serviceWorker.controller) {
            // no controller!
        } else {
            var $paginationBtn = $('.pagination a.more-button[data-loading-state="unloaded"]');
            var urls = [];

            $paginationBtn.each(function(index, element) {
                var $element = $(element);
                var url = $element.attr('href');

                if (!url || url === window.location.href) {
                    return;
                }

                urls.push(url + '&format=ajax');
            });

            var message = {
                action: 'prefetch',
                urls: urls
            };

            navigator.serviceWorker.controller.postMessage({message: message});
        }
    }
}

function getTotalProductCount() {
    var count = ($('input[name=totalProductsCount]').length > 0)
        ? parseInt($('input[name=totalProductsCount]').val())
        : parseInt($('input[name=productsCount]').last().val());
    return count;
}

function appendPage(page) {
    var pages = [];

    if (pages.filter(function (p) { return p.count === page.count; }).length > 0) {
        return pages;
    } else {
        pages.push(page);
        return pages;
    }
}

function cleanUpPagingJSONAndStorage(pagingJSON) {
    var gridKeys = Object.keys(pagingJSON);

    for (var i = 0; i < gridKeys.length; i++) {
        var gridKey = gridKeys[i];

        if (gridKey !== 'url') {
            var loadMoreKeys = Object.keys(pagingJSON[gridKey]);

            for (var j = 0; j < loadMoreKeys.length; j++) {
                var loadMoreKey = loadMoreKeys[j];

                sessionStorage.removeItem(pagingJSON[gridKey][loadMoreKey]);
                delete pagingJSON[gridKey][loadMoreKey];
            }
        }

        delete pagingJSON[gridKey];
    }

    return pagingJSON;
}

function savePageState(response, grid, ajaxUrl) {
    var isgridFilterInvalid = util.isInvalidValue(grid);
    if (isgridFilterInvalid) {
        grid = 'default';
    }

    try {
        var pagingString = sessionStorage.getItem('pagination');
        var pagingJSON;

        if (pagingString !== null) {
            pagingJSON = JSON.parse(pagingString);

            if (!('url' in pagingJSON)) {
                pagingJSON.url = window.location.href;
            } else if (pagingJSON.url !== window.location.href) {
                pagingJSON = cleanUpPagingJSONAndStorage(pagingJSON);
                pagingJSON.url = window.location.href;
            }

            if (!(grid in pagingJSON)) {
                pagingJSON[grid] = [];
            }
        } else {
            pagingJSON = {};
            pagingJSON.url = window.location.href;
            pagingJSON[grid] = [];
        }

        var pagePreLoaded = false;

        for (var i = 0; i < pagingJSON[grid].length; i++) {
            if (pagingJSON[grid][i] === ajaxUrl) {
                pagePreLoaded = true;
            }
        }

        if (!pagePreLoaded) {
            pagingJSON[grid].push(ajaxUrl);
        }

        sessionStorage.setItem('pagination', JSON.stringify(pagingJSON));

        var productCount = getTotalProductCount();
        var pages = appendPage({count: productCount, response: response, scrollTop: $(window).scrollTop()});

        sessionStorage.setItem(ajaxUrl, JSON.stringify(pages));
    } catch (error) {
        // continue regardless of error
    }
}

/**
 * remove all load more pages that is stored in the session if the current window url doesn't match the url in the storage.
 * @returns
 */
function clearSessionStorage() {
    var pagingString = sessionStorage.getItem('pagination');

    if (pagingString === null) {
        return;
    }

    var pagingJSON = JSON.parse(pagingString);

    if ('url' in pagingJSON && pagingJSON.url !== window.location.href) {
        sessionStorage.setItem('pagination', JSON.stringify(cleanUpPagingJSONAndStorage(pagingJSON)));
    }

}

function getProductTileHeight() {
    var calculateProductTileHeight = function() {
        const productTile = document.querySelector('#primary .product-tile');

        if (productTile) {
            productTileHeight = productTile.offsetHeight;
        }

        return productTileHeight;
    };

    if (!productTileHeightListener) {
        productTileHeight = calculateProductTileHeight();

        window.addEventListener('resize', _debounce(() => {
            productTileHeight = calculateProductTileHeight();
        }, 300));

        productTileHeightListener = true;
    }

    return productTileHeight;
}

function reveal($set) {
    var count = $set.length;
    var nn = 0;
    var fadeIn = function () {
        if (nn < count) {
            gsap.to($set[nn], {
                autoAlpha: 1,
                duration: 0.5
            });
            nn++;
            window.requestAnimationFrame(fadeIn);
        }
    };
    fadeIn();
}

function addGhosting() {
    const $primaryContent = $('.primary-content');

    if ($primaryContent.find('.gridbreak-content').length) {
        $primaryContent.find('.gridbreak-content').hide();
    } else {
        $primaryContent.find('.search-result-content').hide();
    }

    $primaryContent.append($('.ghosting-container').clone(true));
}

function removeGhosting() {
    const $primaryContent = $('.primary-content');

    $primaryContent.find('.ghosting-container').remove();

    if ($primaryContent.find('.gridbreak-content').length) {
        $primaryContent.find('.gridbreak-content').show();
    } else {
        $primaryContent.find('.search-result-content').show();
    }
}

function wishlistInit() {
    require.ensure([], function (require) {
        var chunk = require('./wishlist');
        if (chunk.init) {
            chunk.init();
        }
    }, function (error) {
        console.log('error!', error);
    }, 'wishlist');
}

function wishlistSetFavorites() {
    require.ensure([], function (require) {
        var chunk = require('./wishlist');
        if (chunk.setFavourites) {
            chunk.setFavourites();
        }
    }, function (error) {
        console.log('error!', error);
    }, 'wishlist');
}

function updateProductGridPlaceholderHtml(placeholder, response) {
    var productsFromSessionStorage = placeholder.find('.placeholder-items-separator').nextAll();
    var paginationFromSessionStorage = placeholder.find('.bottom-pagination-plp');
    var $response = $('<div>').append($.parseHTML(response));

    if (productsFromSessionStorage.length) {
        $response.find('.search-result-items').append(productsFromSessionStorage);

        // update pagination
        if (paginationFromSessionStorage.length) {
            $response.find('.bottom-pagination-plp').html(paginationFromSessionStorage.html() || '');
        } else {
            $response.find('.bottom-pagination-plp').remove();
        }
    }

    placeholder.html($response.html()); // Notes current product count in URL.
}

function restoreViewAllLinkState() {
    const viewAllGridContainer = document.querySelector('.js-paginated-container[data-view-all]');

    const observeAndClickViewAllLink = function() {
        const paginationBlock = viewAllGridContainer.querySelector('.bottom-pagination-plp');

        if (paginationBlock) {
            const paginationBlockObserver = new IntersectionObserver(function(entries, observer) {
                if (entries[0].isIntersecting) {
                    const viewAllLink = entries[0].target.querySelector('.pagination a.view-all');

                    // check if link is visible and clickable
                    if (viewAllLink && !!(viewAllLink.offsetWidth || viewAllLink.offsetHeight || viewAllLink.getClientRects().length) && window.getComputedStyle(viewAllLink).visibility !== 'hidden' && window.getComputedStyle(viewAllLink).pointerEvents !== 'none') {
                        observer.disconnect();
                        viewAllLink.click();
                    }
                }
            });

            paginationBlockObserver.observe(paginationBlock);
        }
    };

    if (viewAllGridContainer) {
        if (!viewAllGridContainer.dataset.loadingState || viewAllGridContainer.dataset.loadingState === 'loaded') {
            observeAndClickViewAllLink();
        } else {
            const gridBreakLoadedHandler = function _gridBreakLoadedHandler(e) {
                if (e.detail === viewAllGridContainer.getAttribute('url')) {
                    observeAndClickViewAllLink();
                    document.removeEventListener('gridBreakLoaded', _gridBreakLoadedHandler);
                }
            };

            document.addEventListener('gridBreakLoaded', gridBreakLoadedHandler);
        }
    }
}

exports.watchNewRlcBlocks = watchNewRlcBlocks;
exports.emptyProduct = emptyProduct;
exports.filterControlSwitch = filterControlSwitch;
exports.resetRefinement = resetRefinement;
exports.priceLabelPosition = priceLabelPosition;
exports.updateProductWindowBack = updateProductWindowBack;
exports.initBackButtonforQS = initBackButtonforQS;
exports.initQS = initQS;
exports.storePlpScrollPosition = storePlpScrollPosition;
exports.prefetch = prefetch;
exports.getTotalProductCount = getTotalProductCount;
exports.savePageState = savePageState;
exports.clearSessionStorage = clearSessionStorage;
exports.getProductTileHeight = getProductTileHeight;
exports.reveal = reveal;
exports.addGhosting = addGhosting;
exports.removeGhosting = removeGhosting;
exports.wishlistInit = wishlistInit;
exports.wishlistSetFavorites = wishlistSetFavorites;
exports.updateProductGridPlaceholderHtml = updateProductGridPlaceholderHtml;
exports.restoreViewAllLinkState = restoreViewAllLinkState;
