'use strict';

var ccValidator = {

    /**
     * Initialize our validator.
     *
     * Gathers credit card number from cc-number class and calls
     * validateCreditCardNumber then calls hideError on valid number or
     * displayError on invalid value.
     *
     * Looks for class value of cc-number with child of input-text and
     * is triggered on focus out.
     *
     */
    init: function () {
        var $this = this;
        var hasError = $('.cc-number .form-caption').hasClass('error-message');

        // If we already have an error on page load or after submitting the form
        // go ahead and display it otherwise we will continue with our client side
        // validations.
        if (hasError) {
            var selector = $('.cc-number');
            $this.displayError(selector);
        }

        $('.cc-number .input-text').on('focusout',function () {
            var element = $(this);
            var selector = element.closest('.cc-number');

            // We don't want to run this until there is an actual value
            // in the input field this way the required field check
            // will do it's magic first.
            if (element.val()) {
                var isValid = $this.validateCreditCardNumber(element);

                if (isValid) {
                    $this.hideError(selector);
                } else {
                    $this.displayError(selector);
                }
            } else {
                $this.hideError(selector);
                $(selector).find('.form-caption').text('');
            }

        });
    },

    /**
     * Loop through our regex array and test card number.
     *
     * @param element $(this) Element being interacted with by user.
     * @return boolean
     */
    validateCreditCardNumber: function (element) {
        var ccNum = element.val();
        var isValid = false;
        var ccRegex = {
    		visaRegEx: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
            mastercardRegEx: /^(?:[2,5][1-7][0-9]{14})$/,
            amexpRegEx: /^(?:3[47][0-9]{13})$/,
            discovRegEx: /^65[0-9]{14}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
            maskedRegex: /^\*+[0-9]{4}$/,
            jcb: /^(?:2131|1800)\d{11,14}|352[8-9]\d{12,15}|35[3-7]\d{13,16}|35[8]\d{13,16}$/,
            diners: /^30[0-5]\d{13,16}|3095\d{12,15}$/
        };

        for (var key in ccRegex) {
            var value = ccRegex[key];
            if (value.test(ccNum)) {
                isValid = true;
            }
        }

        if (isValid) {
            return true;
        } else {
            return false;
        }

    },

    /**
     * Called when we have an invalid card number and populates our
     * elements with the proper class attributes to display errors.
     *
     * @param selector $(this) Element being interacted with by user.
     */
    displayError: function (selector) {
        var $this = this;
        var errorMessage = Resources.VALIDATE_CREDITCARD_NO;
        $(selector).find('.input-text').addClass('cc-error');
        $(selector).find('.form-caption').addClass('cc-error');
        $(selector).find('.form-caption').text(errorMessage);

        if (!$('.error-banner').length) {
            $this.addErrorBanner(errorMessage);
            $this.addErrorAlertIcon(selector);
        }
    },

    /**
     * Called when we have a valid card number and removes our error values.
     *
     * @param selector $(this) Element being interacted with by user.
     */
    hideError: function (selector) {
        $(selector).find('.input-text').removeClass('cc-error');
        $(selector).find('.form-caption').removeClass('cc-error');
        $(selector).find('.form-caption').removeClass('error-message');  
        $(selector).find('.form-caption').text('');
        $('.error-banner').remove();
        $('.error-field-tooltip').remove();
    },

    /**
     * Adds small red alert icon to input field
     *
     * @param selector $(this) Element being interacted with by user.
     */
    addErrorAlertIcon: function (selector) {
        var selector = $(selector).find('.field-wrapper');
        var toolTip = $('<div class="error-field-tooltip"></div>');
        toolTip.insertAfter(selector);
    },

    /**
     * Adds fullwidth red banner at the top of our page to display the error.
     *
     * @param errorMessage string Error Message.
     */
    addErrorBanner: function (errorMessage) {
        $('<div class="error-banner"><p><span class="alert-icon"></span> ' + errorMessage + '</p></div>').insertAfter('.top-banner');
    }

};

module.exports = ccValidator;
