'use strict';

var ajax = require('./ajax'),
    util = require('./util'),
    _ = require('lodash'),
    imagesLoaded = require('imagesloaded');

function dialogCloseFun() {
	//click/touch event to close the dialog
    $('.ui-dialog button.ui-dialog-titlebar-close').off('click touchstart').on('click touchstart',function(e){
    	e.preventDefault();
    	var thisDialog = $(this).closest('.ui-dialog');
    	if ((thisDialog.hasClass('wishlist-dialog')) && $(window).width() < 960) {
    		thisDialog.removeClass('translateYZero');
    		$('.ui-widget-overlay').fadeOut('slow', function(){
    		    $(this).remove();
    		});
    		setTimeout(function(){ 
    	    	thisDialog.find('.ui-dialog-content').dialog('close').remove();
    	    	thisDialog.remove();
        	},750 );
    	} else if ((thisDialog.hasClass('modal-fadeout-desktop')) && $(window).width() > 960) {
    		thisDialog.removeClass('modal-fadeout-desktop');
    		thisDialog.fadeOut( 300 );
    		setTimeout(function(){ 
    	    	thisDialog.find('.ui-dialog-content').dialog('close').remove();
    	    	thisDialog.remove();
        	},500 );
    	} else {
            if (thisDialog.hasClass('emailOnce') && window.MedalliaSurveytShow == false){
                window.MedalliaSurveytShow = true;
            }
    		thisDialog.find('.ui-dialog-content').dialog('close').remove();
	    	thisDialog.remove(); //removing dialog div from DOM once closing the dialog
	    	$(document).trigger('dialog-evt','close');
    	}
    	return false;
    });
}
var dialog = {
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create: function (params) {
        var $target, id;

        if (_.isString(params.target)) {
        	var targetID = params.target;
        	id = targetID;
            if (targetID && targetID.charAt(0) === '#') {
                id = targetID.substr(1);
            }
            $target = $('<div>').attr('id', id).addClass('dialog-content').appendTo('body');
        } else if (params.target instanceof jQuery) {
        	$target = params.target;
        } else {
            $target = $('<div>').attr('id', 'dialog-container').addClass('dialog-content').appendTo('body');
        }
        // create the dialog
        this.$container = $target;
        this.$container.dialog(_.merge({}, this.settings, params.options || {}));
        dialogCloseFun();
        return this.$container; // PREVAIL-Added Return value.
    },
    /**
     * @function
     * @description Opens a dialog using the given url (params.url) or html (params.html)
     * @param {Object} params
     * @param {Object} params.url should contain the url
     * @param {String} params.html contains the html of the dialog content
     */
    open: function (params) {
        // close any open dialog
        this.create(params);
        this.replace(params);
        dialogCloseFun();
    },
    /**
     * @description populate the dialog with html content, then open it
     **/
    openWithContent: function (params) {
        var content, position, callback;

        if (!this.$container) {
            return;
        }
        content = params.content || params.html;
        if (!content) {
        	if(params.$append){
        		this.$container.empty().append(params.$append) 
			}else{        	
				return;
			}
        } else {
        	this.$container.empty().html(content);
        }

        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
        }

        if (params.options) {
            position = params.options.position;
        }
        if (!position) {
            position = this.settings.position;
        }
        imagesLoaded(this.$container).on('done', function () {
            this.$container.dialog('option', 'position', position);
        }.bind(this));

        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        

        $('.ui-widget-overlay').on('click', function () { 
            var index_highest = 0;   

            $('.ui-widget-overlay').each(function() {

                var index_current = parseInt($(this).css("zIndex"), 10);
                if(index_current > index_highest) {
                    index_highest = index_current;
                }
            });
            $('.ui-dialog').each(function() {
                if($(this).css('z-index') == index_highest + 1) {
                	if (($(this).hasClass('translateYZero')) && $(window).width() < 960) {
                		var thisModal = $(this).removeClass('translateYZero');
                		$('.ui-widget-overlay').fadeOut('slow', function(){
                		    $(this).remove();
                		});
                		setTimeout(function(){ 
                			thisModal.find('.ui-dialog-content').dialog('close').remove();
                			thisModal.remove();
                    	},750 );
                	} else {
                        $(this).find('.ui-dialog-content').dialog('close').remove(); 
                        $(this).remove();	//removing dialog div from DOM once closing the dialog
                        $(document).trigger('dialog-evt','close');
                	}
                    $('body').css('overflow', 'auto');
                }
            });
        });

        this.$container.find('select').each(function() {
            if ($(this).find('option:selected').val() === null || $(this).find('option:selected').val() === '') {
                $(this).removeClass('valid');
            } else {
                $(this).addClass('valid');
            }
        });

        this.$container.find('select').on('change', function() {
            if ($(this).find('option:selected').val() === null || $(this).find('option:selected').val() === '') {
                $(this).removeClass('valid');
            } else {
                $(this).addClass('valid');
            }
        });

        this.$container.find('.input-text, .input-textarea').each(function() {
            var $this = $(this);
            if ($this.val().length === 0) {
                $this.addClass('empty');
            } else {
                $this.removeClass('empty');
            }
        });

        //A workaround for IE-11 because :placeholder-shown dose not work on IE-11
        this.$container.find('.input-text, .input-textarea').on('change blur input', function() {
            var $this = $(this);
            if ($this.val().length === 0) {
                $this.addClass('empty');
            } else {
                $this.removeClass('empty');
            }
        });

        $(document).trigger('dialog-evt','open');
        callback(this.$container);
    },
    /**
     * @description Replace the content of current dialog
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    replace: function (params) {
        if (!this.$container) {
            return;
        }
        $(document).trigger('dialog-evt','replace');
        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            ajax.load({
                url: params.url,
                data: params.data,
                callback: function (response) {
                    params.content = response;
                    this.openWithContent(params);
                }.bind(this)
            });
        } else if (params.html) {
            this.openWithContent(params);
        }
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        if (!this.$container) {
            return;
        }
        if(this.$container.dialog && this.$container.dialog('instance')){
        	this.$container.dialog('close');
        }
        this.$container.remove();
        this.$container.closest('.ui-dialog').remove();
        $(document).trigger('dialog-evt','close');
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    },
    isActive: function () {
        return this.exists() && (this.$container.children.length > 0);
    },
    settings: {
        autoOpen: false,
        height: 'auto',
        modal: true,
        overlay: {
            opacity: 0.5,
            background: 'black'
        },
        resizable: false,
        title: '',
        width: '50%',
        close: function (e) {
            // try to stop the event
            e.stopPropagation();
            e.preventDefault();

            $(this).dialog('close').remove();
            $(this).closest('.ui-dialog').remove();
            $(document).trigger('dialog-evt','close');
        },
        position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'flipfit'
        }
    }
};

// adds internal scroll if height is too small
var adjustHeight = _.debounce(function () {
    if ($(window).width() > 959) {
        var h = $(window).height();
        $('.dialog-content.ui-dialog-content').each(function () {
            var $content = $(this);
            var $dialog = $content.closest('.ui-dialog');
            $content.dialog('option', 'maxHeight', h);
            $dialog.css({position: 'fixed'}).position($content.dialog('option', 'position'));
        });
    }
});
function onDialog(e, type) {
    if (type === 'open' || type === 'replace') {
        $(window).off('resize', adjustHeight).on('resize', adjustHeight);
        adjustHeight();
        if ($(window).width() > 959) {
            $('.ui-dialog-content').scrollTop(0); 
        } else {
            $('.full-width-fixed-dialog').scrollTop(0); 
        }
    } else {
        if (!$('.dialog-content.ui-dialog-content').length) {
            $(window).off('resize', adjustHeight);
        }
    }
}

$(document).off('dialog-evt', onDialog).on('dialog-evt', onDialog);

module.exports = dialog;
