'use strict';

var util = require('./util');

module.exports = {
	isModalAvailable: function() {
		var optanonConsentCookie = util.getCookie('OptanonConsent');
		var optanonConsentParams = new URLSearchParams(optanonConsentCookie);

		return 'oneTrustAvailable' in window && oneTrustAvailable && optanonConsentParams.get('landingPath') != 'NotLandingPage';
	},
	// waiting for OneTrust full load
	onReady: function(callback) {
		// max waiting time is 5 min
		var maxCheckCalls = 10 * 60 * 5;
		var callDuration = 100;
		var actualCheckCalls = 0;

		var id = setInterval(function() {
			if (actualCheckCalls >= maxCheckCalls) {
				clearInterval(id);
			} else if ('OneTrust' in window && OneTrust) {
				clearInterval(id);
				callback();
			}

			actualCheckCalls++;
		}, callDuration);
	},
	onModalClose: function(callback) {
		this.onReady(function() {
			OneTrust.OnConsentChanged(callback);
		});
	},
};
