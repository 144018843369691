'use strict';

var initialized = false;
var $leftCol,
    $rightCol,
    raf,
    lastScrollTop = -1,
    $col1,
    $col2,
    $header,
    $headerCont,
    $headerBanner,
    $globalErrMsg,
    $atb;
var winHeight,
    shortColumn;

function setValues() {
    $leftCol = $('.product-col-1 >div:first-of-type');
    $rightCol = $('.product-col-2 >div:first-of-type');
    $headerCont = $('header');
    $headerBanner = $('.header-banner');
    $globalErrMsg = $('.global-critical-err-msg');
    $atb = $('.addtocart');
}

function setCol2Values(isTablet) {
    $col2 = $('.product-col-2').not('.sub-product-col-2');
    $col1 = $('.product-col-1').not('.sub-product-col-1');
    $headerCont = $('header');
    $header = $('.top-banner-sticky');
}



//PDP Page Scrolling
function update(e) {
    // only act on the right column
    // make sure addToBag is visible
    winHeight = document.documentElement.clientHeight;
    //var addToBagBottom = document.getElementById('add-to-cart').getBoundingClientRect().bottom;
    $rightCol.removeClass('is-sticky');
    var addToBagBottom = $atb.length ? $atb.offset().top + $atb.height() : 0;
    var rightColTop = $rightCol.offset().top;
    bannerHeight = 0;
    var stickyTop;
    if (addToBagBottom > winHeight) {
        // adding 60px for padding
        //stickyTop = winHeight + rightColTop  - addToBagBottom - 60;
    } else {        
        if (!e || e.type == 'resize') {
            promoAtTop = !$headerCont.hasClass('promo-bottom');
            nonStickyPromo = $headerCont.find('.no-sticky-banner').length > 0;
            if ($globalErrMsg.length) {
                bannerHeight = $globalErrMsg.outerHeight();
            } 
            if ($headerBanner.length && nonStickyPromo && promoAtTop) {
                if ($headerBanner.is(':visible')) {
                    bannerHeight += $headerBanner.outerHeight();
                } else {
                    bannerHeight += $headerBanner.addClass('is-flex').outerHeight();
                    $headerBanner.removeClass('is-flex');
                }
            } 
             
            
        }
        stickyTop = rightColTop - bannerHeight;        
    }
    if (stickyTop){
        $rightCol.addClass('is-sticky').css({top: stickyTop + 'px'});
    }
    
}

function resetStyles() {
    if ($leftCol) {
        $leftCol.removeClass('pdp-scroll-abs').removeClass('pdp-scroll-fixed');
        $leftCol.css('top', 'auto').css('bottom', 'auto');
    }
    if ($rightCol) {
        $rightCol.removeClass('pdp-scroll-abs').removeClass('pdp-scroll-fixed');
        $rightCol.css('top', 'auto').css('bottom', 'auto');
    }
    if (shortColumn) { shortColumn.width('auto') };
}
function shouldUpdate(e) {
    // If there's a timer, cancel it
    if (raf) {
        window.cancelAnimationFrame(raf);
    }

    // Setup the new requestAnimationFrame()
    if($rightCol.length){
        raf = window.requestAnimationFrame(function() {
            update(e);
        });
    }
    
}

var  bannerHeight, promoAtTop, nonStickyPromo, mobileImageSliderHeight, navigationHeight, transparentNav;

function col2Update(e) {
    // 75% is defined as $refresh-tablet-swiper-width in refresh scss
	var mobileImageSliderHeight = ($('.gift-card-refresh').length > 0) ? '15px' : '28px';

    var pos = $(window).scrollTop();
    if (!e || e.type == 'resize') {
        bannerHeight = $('.header-banner:visible').length ? $('.header-banner:visible').outerHeight() : 0;
        promoAtTop = !$headerCont.hasClass('promo-bottom');
        nonStickyPromo = $headerCont.find('.no-sticky-banner').length > 0;
        navigationHeight = $('.top-banner').outerHeight();
        transparentNav = $headerCont.hasClass('transparent');

        if (nonStickyPromo && promoAtTop) {
            $col1.css('position', 'sticky');
            $col1.css('top', transparentNav ? 0 : navigationHeight);
            $col2.css('margin-top', mobileImageSliderHeight);
        } else if (nonStickyPromo && !promoAtTop) {
            $col1.css('position', 'sticky');
            $col1.css('top', navigationHeight);
            $col2.css('margin-top', mobileImageSliderHeight);
        } else if (!nonStickyPromo && !promoAtTop) {
            $col1.css('position', 'sticky');
            $col1.css('top', navigationHeight + bannerHeight);
            $col2.css('margin-top', mobileImageSliderHeight);
        } else {
            // !nonStickyPromo && promoAtTop
            $col1.css('position', 'sticky');
            $col1.css('top', (transparentNav ? 0 : navigationHeight) + bannerHeight);
            $col2.css('margin-top', mobileImageSliderHeight);
        }
    }
}

function enable() {
    setValues();
    $(window).on('resize', shouldUpdate);
    shouldUpdate();
}

function disable() {
    //remove listeners
    $(window).off('resize', shouldUpdate);
    resetStyles();
}

function col2enable(isTablet) {
    setCol2Values(isTablet);
    $(window).on('scroll', col2Update);
    $(window).on('resize', col2Update);
    col2Update();
}

function col2disable() {
    //remove listeners
    $(window).off('scroll', col2Update);
    $(window).off('resize', col2Update);
    if ($col2) {
        $col2.css('margin-top', '0');
    }
    if ($col1) {
        $col1.css('position', 'relative');
    }
}

function initializeEvents() {
    if (initialized) return;
    initialized = true;

    // uses event to remove tight coupling
    $(document).on("pdpscroll:update", function(e, accordion) {
        sticky_pdp(accordion)
    });
    $(document).on("pdpscroll:enable", function(e) {
        enable();
    });
    $(document).on("pdpscroll:disable", function(e) {
        disable();
    });
    //

    $(document).on("col2scroll:enable", function(e, isTablet) {
        col2enable(isTablet);
    });
    $(document).on("col2scroll:disable", function(e) {
        col2disable();
    });
};


exports.init = function() {

    initializeEvents();


};

exports.setValues = setValues;
exports.sticky_pdp = function() {};
