'use strict';


function TradeAddressDialog($form, $editAddrForm, formLabel) {
	var $formCountry = $form.find('select[id$="_businessinformation_countries_country"] option:selected').val()
	var $formState;
	if($formCountry == 'US'){
		$formState = $form.find('select[id$="_businessinformation_states_stateCode"]');
	} else {
		$formState = $form.find('input[id$="_businessinformation_otherstate"]')
	}
	
    function markEmpty() {
        var $this = $(this);
        if ($this.val() == null || $this.val().length == 0) {
            $this.addClass('empty');
        } else {
            $this.removeClass('empty');
        }
    }

    function markAllAsValid($editAddrForm) {
        $editAddrForm.find('select').each(function () {
            $(this).addClass('valid');
        });
    }

    function onCancel() {
        closeDialog(this); // Passes DOM element through from handler call.
    }
    
    function closeDialog(element) {
        $(element).closest('.ui-dialog').find('.ui-dialog-titlebar-close').trigger('click');
    }

    function markSelectedAeState($editAddrForm, formLabel) {
        $editAddrForm.find('select[id$="_stateCode"]').find('option').each(function (index, element) {
            if (element.value == 'AE') {
                $(element).prop('selected', element.text == formLabel);
            }
        });
        $editAddrForm.find('select[id$="_businessinformation_states_stateCode"]').trigger('change');
    }

    function setFormValuesFromEditForm() {
        var $editFormState;
    	var $editselectedCountry = $editAddrForm.find('select[name$="_tradeprogram_businessinformation_countries_country"] option:selected').val();
    	if($editselectedCountry == 'US') {
    		$editFormState = $editAddrForm.find('select[id$="_businessinformation_states_stateCode"] option:selected').val();
    	} else {
    		$editFormState = $editAddrForm.find('input[id$="_businessinformation_otherstate"]').val();
    	}
        setFormValues({
            firstName: $editAddrForm.find('input[name$="dwfrm_tradeprogram_tradeusercontact_firstName"]').val(),
            lastName: $editAddrForm.find('input[name$="dwfrm_tradeprogram_tradeusercontact_lastName"]').val(),
            address1: $editAddrForm.find('input[name$="_businessinformation_address1"]').val(),
            address2: $editAddrForm.find('input[name$="_businessinformation_address2"]').val(),
            state: $editFormState,
            city: $editAddrForm.find('input[name$="_businessinformation_city"]').val(),
            country:$editselectedCountry,
            postalCode: $editAddrForm.find('input[name$="_businessinformation_postal"]').val(),
            stateOptionLabel: $editAddrForm.find('select[id$="_businessinformation_states_stateCode"] option:selected').text() || $editFormState
        });
    }
    
    function setFormValues(address) {
        if (address.firstName) {
            $form.find('input[name$="dwfrm_tradeprogram_tradeusercontact_firstName"]').val(address.firstName);
        }
        if (address.lastName) {
            $form.find('input[name$="dwfrm_tradeprogram_tradeusercontact_lastName"]').val(address.lastName);
        }
        if (address.address1) {
            $form.find('input[name$="_businessinformation_address1"]').val(address.address1);
        }
        if (address.address2) {
            $form.find('input[name$="_businessinformation_address2"]').val(address.address2);
        }
        if (address.stateCode) {
        	$formState.val(address.stateCode);
        }
        if (address.state) {
        	$formState.val(address.state);
        }
        if (address.city) {
            $form.find('input[name$="_businessinformation_city"]').val(address.city);
        }
        if (address.postalCode) {
            $form.find('input[name$="_businessinformation_postal"]').val(address.postalCode);
        }
        if (address.country) {
            $form.find('input[name$="_businessinformation_country"]').val(address.country);
        }
        if (address.stateOptionLabel) {
            $form.find('select[id$="_businessinformation_states_stateCode"]').find('option').each(function (index, element) {
                if (element.value == 'AE') {
                    $(element).prop('selected', element.text == address.stateOptionLabel);
                }
            });
        }
    }

    function apply() {
        if ($editAddrForm.valid()) {
            setFormValuesFromEditForm();
            closeDialog(this); // Bound as event handler below.
            $formState.trigger('change');
            //$('div.dyn-form-shipping').removeClass('hide');
        }
    }
    
    function useSuggested() {
        var card = $editAddrForm.find('.miniaddress.suggestion');
        var address = card.data('address');
        setFormValues(address);
        closeDialog(this); // Bound as event handler below.
        $formState.trigger('change');
        //$('div.dyn-form-shipping').removeClass('hide');
    }
    
    function skipSuggested() {
		isExperianEnabled = false;
		$('button[name$="_tradeprogram_submittradeform"]').trigger("click");
		closeDialog(this);
    }
    
    function checkFieldsChange() {
	    $editAddrForm.find('input, select').on("change input", function(){
	    	$('#dialogApplyBtn').show();
	    	$('#dialogSkipVerificatinBtn').hide();
		});
    }
    
    function showEditTab() {
        $editAddrForm.find('.address-edit-tab').show();
        $editAddrForm.find('.address-verification-tab').hide();
    }

    this.init = function () {
        markAllAsValid($editAddrForm);
        $('.input-text, .input-textarea').on('change blur input', markEmpty);
        markSelectedAeState($editAddrForm, formLabel);
        checkFieldsChange();
        $editAddrForm.off('click', '#dialogCancelBtn').on('click', '#dialogCancelBtn', onCancel);
        $editAddrForm.off('click', '#dialogApplyBtn').on('click', '#dialogApplyBtn', apply);
        $editAddrForm.off('click', '#dialogConfirmBtn').on('click', '#dialogConfirmBtn', useSuggested);
        $editAddrForm.off('click', '#dialogSkipVerificatinBtn').on('click', '#dialogSkipVerificatinBtn', skipSuggested);
        $editAddrForm.off('click', '.edit-button').on('click', '.edit-button', showEditTab);
    };
}

module.exports = TradeAddressDialog;