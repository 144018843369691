'use strict';

var onetrust = require('./onetrust');
var util = require('./util');

var $document = $(document);
var $welcomeModal = $('.js-welcome-modal');
var triggerWelcomeModalClose = true;

function openWelcomeModal() {
	util.setCookie('welcomeModalLoad', 1);

	$welcomeModal.dialog({
		autoOpen: true,
		width: 730,
		dialogClass: "dialog-welcome-modal" + ($welcomeModal.data('dialogclass') ? ' ' + $welcomeModal.data('dialogclass') : ''),
		modal: true,
		close: function() {
			if (triggerWelcomeModalClose) {
				$document.trigger('welcomemodal.closed');
			}
		},
	});
}

function initializeEvents() {
	$('.welcome-continue-shop').on('click', function(e) {
		e.preventDefault();
		$welcomeModal.dialog('close');
	});

	$('.js-welcome-change-destination').on('click', function(e) {
		if (!util.isMobile() || (util.isTablet() && util.isLandscapeOrientation())) {
			e.preventDefault();
			triggerWelcomeModalClose = false;
			$welcomeModal.dialog('close');
			$document.trigger('countryselector.show');
		}
	});
}

module.exports = {
	isAvailable: function() {
		return $welcomeModal.length;
	},
	init: function() {
		if (!this.isAvailable()) {
			return;
		}

		if (onetrust.isModalAvailable()) {
			onetrust.onModalClose(openWelcomeModal);
		} else {
			openWelcomeModal();
		}

		initializeEvents();
	},
};
