'use strict';

let initialized = false;

const _debounce = require('lodash/debounce');

// export const SMALL = 'small';
// export const MEDIUM = 'medium';
// export const LARGE = 'large';
// export const EXTRA_LARGE = 'extraLarge';

export const SMALL = '1';
export const MEDIUM = '2';
export const LARGE = '4';
export const EXTRA_LARGE = '8';

let currentViewType = SMALL;

const breakMd = 768;
const breakLg = 960;
const breakXl = 1024;

const smallView = `screen and (max-width: ${breakMd - 1}px)`;
const mediumView = `screen and (min-width: ${breakMd}px) and (max-width: ${breakLg - 1}px)`;
const largeView = `screen and (min-width: ${breakLg}px) and (max-width: ${breakXl - 1}px)`;
const exLargeView = `screen and (min-width: ${breakXl}px)`;

export const queries = {
    smallView: smallView,
    mediumView: mediumView,
    largeView: largeView,
    exLargeView: exLargeView,
    mediumDownView: `screen and (max-width: ${breakMd - 1}px)`,
    mediumUpView: `screen and (min-width: ${breakMd}px)`,
    largeDownView: `screen and (max-width: ${breakLg - 1}px)`,
    largeUpView: `screen and (min-width: ${breakLg}px)`,
}

const queriesMap = [
    [SMALL, smallView],
    [MEDIUM, mediumView],
    [LARGE, largeView],
    [EXTRA_LARGE, exLargeView]
];

/**
 * Get current view type
 * @returns {string} Current view type
 */
export const getViewType = () => currentViewType;

/**
 * Check if the current view type is equal to the provided one
 * @param {string} type name of the type to check
 * @returns {boolean} Return true if a provided type is equal to the current view type
 */
function isViewTypeMatch(type) {
    return getViewType() === type;
}

/**
 * Check if view type is small
 * @returns {boolean}
 */
export const isSmallView = () => isViewTypeMatch(SMALL);

/**
 * Check if view type is medium
 * @returns {boolean}
 */
export const isMediumView = () => isViewTypeMatch(MEDIUM);

/**
 * Check if view type is medium and up
 * @returns {boolean}
 */
export const isMediumViewAndUp = () => !isSmallView();

/**
 * Check if view type is medium and down
 * @returns {boolean}
 */
export const isMediumViewAndDown = () => isSmallView() || isMediumView();

/**
 * Check if view type is extra large
 * @returns {boolean}
 */
export const isExtraLargeView = () => isViewTypeMatch(EXTRA_LARGE);

/**
 * Check if view type is large
 * @returns {boolean}
 */
export const isLargeView = () => isViewTypeMatch(LARGE);

/**
 * Check if view type is large and up
 * @returns {boolean}
 */
export const isLargeViewAndUp = () => isLargeView() || isExtraLargeView();

/**
 * Check if view type is large and down
 * @returns {boolean}
 */
export const isLargeViewAndDown = () => !isExtraLargeView();

/**
 * Get current view type
 * @returns {string} Current view type
 */
function getCurrentViewType() {
    const matchQuery = queriesMap.find(([, query]) => window.matchMedia(query).matches);

    return (matchQuery && matchQuery[0]) || SMALL;
}

/**
 * Sends an event to invoke view type change listeners
 * @param {string} current Current view type
 * @param {string|undefined} previous Previous view type
 */
function triggerViewTypeChangeEvent(current, previous) {
    document.dispatchEvent(new CustomEvent('viewTypeChange', {
        detail: { current, previous }
    }));
}

/**
 * Compares view types an triggers event in case of change
 * @param {string} newViewType
 */
function matchViewport(newViewType) {
    const previousViewType = currentViewType;

    currentViewType = newViewType;

    if (previousViewType !== currentViewType) {
        triggerViewTypeChangeEvent(currentViewType, previousViewType);
    }
}

export function init() {
    if (!initialized) {
        initialized = true;

        const applyCurrentDeviceType = _debounce(() => matchViewport(getCurrentViewType()), 50);

        queriesMap.forEach(([, query]) => window.matchMedia(query).addEventListener('change', applyCurrentDeviceType));

        currentViewType = getCurrentViewType();

        triggerViewTypeChangeEvent(currentViewType);
    }
}
