'use strict';

var dialog = require('../dialog'),
	initBannerScroll = require('./banner-scroll'),
    util = require('../util'),
    validator = require('../validator'),
    toaster = require('../toaster'),
	AddressDialog = require('./tradeaddressdialog'); //moved tradeaddressdialog from checkout folder and delete the folder


var setMailingAddress = function(mailingaddress, businessaddress) {
	var businessname = $('input[name$=_businessname]', businessaddress).val();
	var address1 = $('input[name$=_address1]', businessaddress).val();
	var adrress2 = $('input[name$=_address2]', businessaddress).val();
	var city = $('input[name$=_city]', businessaddress).val();
	var postCode =  $('input[name$=_postal]', businessaddress).val();
	var stateCode = $('select[name$=_stateCode]', businessaddress).val();
	var country = $('select[name$=_country]', businessaddress).val();
	$('input[name$=_mailingaddressname]', mailingaddress).val(businessname);
	$('input[name$=_address1]', mailingaddress).val(address1);
	$('input[name$=_address2]', mailingaddress).val(adrress2);
	$('input[name$=_city]', mailingaddress).val(city);
	$('input[name$=_postal]', mailingaddress).val(postCode);
	$('select[name$=_stateCode]', mailingaddress).val(stateCode).trigger('change');
	$('select[name$=_country]', mailingaddress).val(country).trigger('change');
}

var setFieldRequired = function (wrapper, isRequired){
	var field = wrapper.querySelector('input');
	if (isRequired && !wrapper.classList.contains('required')) {
		wrapper.classList.add('required');
		wrapper.querySelector('label').insertAdjacentHTML('afterbegin', '<span class="required-indicator"> * </span>');
		$(field).rules('add', {required: true});
	   //$(field).trigger('blur');
	} 

	if (!isRequired && wrapper.classList.contains('required')) {
		wrapper.classList.remove('required');
		wrapper.querySelector('label span').remove();
		$(field).rules('remove', 'required');
		$(field).trigger('blur');
	}
}

var interval = 0;
var countdownTimer = function () {
	// Countdown Timer for session Timeout
	var sessionTime = $('input[name$="sessionStartTime"]');
	if (sessionTime.length > 0) {
		var startTime = new Date(Number(sessionTime.val()) + 30 * 60000);
		interval = setInterval(function() {
			// Get today's date and time
			var now = new Date();
			// Find the distance between now and the count down date
			var distance = startTime - now;
			// Display the result in the element with id="demo"
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
			// If the count down is finished, write some text
			if (minutes == 1 && seconds == 30 && $('.rl-toaster-overlay.opened').length == 0 ) {
				var warn = $('.session-warn').clone();
				if ($(window).width() <= 767) {
					toaster.teleport($(warn), 'bottom', null, 'full-height session-timeout-modal')
					.then();
					document.addEventListener('toaster:close', function(e){
						continueSession();
					});
				} else {
					toaster.teleport($(warn), 'right', null, 'full-height session-timeout-modal')
						.then();
					document.addEventListener('toaster:close', function(e){
						continueSession();
					});
				}
			} else if (distance < 0) {
				clearInterval(interval);
				window.location.href = util.appendParamsToUrl($('a.header-authenticated-user-welcome-signout').attr("href"),{redirectFromtradeTimeout : true});
			}
		}, 1000);
	}
}
 
var continueSession = function () {
	var generateCSRFURL = $('.session-warn').find('button[type="submit"]').data('generatecsrf');
	if (generateCSRFURL !== null) {
		$.ajax({
			type: 'POST',
			url: generateCSRFURL,				
			success: function(res) {
				if(res.csrfToken) {
					$("input[name*='csrf_token']").val(res.csrfToken);
					$('input[name$="sessionStartTime"]').val(res.sessionTimestamp);
					clearInterval(interval);
					countdownTimer();
					$('.rl-toaster-close').trigger("click");
					$('.rl-toaster-overlay').remove();
				}
			}
		});
	}
}

var tradeuser = {
    init: function() {
		initBannerScroll.init();
    	var $curForm = $("#TradeprogramForm");
		var $requiredInputs = $('.required', $curForm).find(':input');
		var existing = false;
		var hasEmptyRequired = function () {
			var errors = 0;
		    var hasEmpty = true;
		    $requiredInputs.filter(':visible').map(function () {
		        if( !$(this).val() ) {
		            errors++;
		        } 
		    });
		    if(errors > 0){
		        hasEmpty = true;
		    } else {
		    	hasEmpty = false;
		    }

		    return hasEmpty;
		};

		var db = function (fn, time) {
			let wait;
			return function (...args) {
				if (wait) clearTimeout(wait);
				wait = setTimeout(() => fn(...args), time);
			};
		}

		var btnState = function(){
    		if(!hasEmptyRequired() && !$('.trade-form-continue').hasClass('hide')) {
    			$curForm.validate();
	        	if ($curForm.valid()) {
	        		$('.trade-form-continue').prop('disabled', false);
					$('.trade-form-click').prop('disabled', false);
	        	} 
	        } else if (!hasEmptyRequired()) {
	        	$curForm.validate();
	        	if ($curForm.valid()) {
	        		$('.trade-form-click').prop('disabled', false);
	        	} 
	        } else {
	    		$('.trade-form-continue').prop('disabled', true);
	    		$('.trade-form-click').prop('disabled', true);
	    	}
		}

		$requiredInputs.on('keyup', db(btnState, 500));

		//countryselection
    	$curForm.find('.dwfrm_tradeprogram_businessinformation_countries_country').addClass('country');
    	$curForm.find('select#dwfrm_tradeprogram_businessinformation_states_stateCode,select#dwfrm_tradeprogram_mailingaddress_states_stateCode').addClass("required");
    	$curForm.find('.dwfrm_tradeprogram_businessinformation_otherstate').addClass("hide");
    	$curForm.find('.dwfrm_tradeprogram_mailingaddress_otherstate').addClass("hide");
    	var selectedcountry = $curForm.find('select[name$="_tradeprogram_businessinformation_countries_country"]').find('option:selected').val();
    	var $companyRegNumberWrapper = $curForm.find('.input-wrapper[class$="_companyRegisteredNumber"]');

    	if(selectedcountry !== 'US') {
    		$curForm.find('.dwfrm_tradeprogram_businessinformation_otherstate,.dwfrm_tradeprogram_mailingaddress_otherstate').removeClass("hide");
    		$curForm.find('.dwfrm_tradeprogram_businessinformation_states_stateCode,.dwfrm_tradeprogram_mailingaddress_states_stateCode').addClass("hide");
    	} else {
    		$curForm.find('.dwfrm_tradeprogram_businessinformation_otherstate,.dwfrm_tradeprogram_mailingaddress_otherstate').addClass("hide");
    		$curForm.find('.dwfrm_tradeprogram_businessinformation_states_stateCode,.dwfrm_tradeprogram_mailingaddress_states_stateCode').removeClass("hide");
    	}

    	$('body').off('click', '.trade-form-click').on('click', '.trade-form-click', function(e) {
    	    e.preventDefault();
    	    var $submitbtn = $('button[name$="_tradeprogram_submittradeform"]');
	        if($('input[name$="dwfrm_tradeprogram_typeofbusiness_other"]').prop('checked')) {
	        	$('input[name$=dwfrm_tradeprogram_typeofbusiness_other2]').addClass('required');
	        } else {
	        	$('input[name$=dwfrm_tradeprogram_typeofbusiness_other2]').removeClass('required')
	        }
	        if($('input[name$="dwfrm_tradeprogram_professionalorganization_other"]').prop('checked')) {
	        	$('input[name$=dwfrm_tradeprogram_professionalorganization_other2]').addClass('required');
	        } else {
	        	$('input[name$=dwfrm_tradeprogram_professionalorganization_other2]').removeClass('required');
	        }
	        
	        var businessCheck = true;
	        if($('.typeofbusiness-checkboxes').hasClass("required")) {
	            $('.typeofbusiness span:not(.required-indicator)').addClass("typeofbusinesserror");
	            $('.typeofbusiness span').removeClass("hide");
	            businessCheck = false;
	        } else {
	        	$('.typeofbusiness span').addClass("hide");
	        	$('.typeofbusiness span').removeClass("typeofbusinesserror");
	        }
	        
	        $curForm.validate();
	        if (!$curForm.valid() || (!businessCheck && !existing)) {
	            return false;
	        }
	        
	        $submitbtn.prop('disabled',true);
	        
    	    if (isExperianEnabled) {
	            var $form = $(this).closest('form');
	            var params = {
	                firstName: $form.find('input[name$="_firstName"]:visible').val(),
	                lastName: $form.find('input[name$="_lastName"]:visible').val(),
	                address1: $form.find('input[name$="_address1"]:visible').val(),
	                address2: $form.find('input[name$="_address2"]:visible').val(),
	                countryCode: $form.find('select[id$="_country"]:visible').val(),
	                stateCode: $form.find('select[id$="_stateCode"]:visible').val() || $form.find('input[id$="_otherstate"]:visible').val(),
	                postalCode: $form.find('input[name$="_postal"]:visible').val(),
	                city: $form.find('input[name$="_city"]:visible').val(),
	                phone: $form.find('input[name$="_phone"]:visible').val()
	            };
	            var params = JSON.stringify(params);
	            var url = util.appendParamsToUrl(Urls.tradeAddressBatchCleansingAPI, {
	                'params': params
	            });
	            var formLabel=''; 
	            if ($form.find('select[id$="_stateCode"]:visible').val() == 'AE') {
	                formLabel=$form.find('select[id$="_stateCode"] option:selected').attr('label');
	            }
	            $.ajax({
	                type: 'POST',
	                url: url,
	                dataType: 'html',
	                success: function(response) {
	                	$submitbtn.prop('disabled',false);
	                    if ($(response).find('#EditAddressForm').length > 0) {
	                        var form = $(response).find('#EditAddressForm');
	                        var dlg = dialog.create({
	                            target: "#Edit-form-addr-dialog",
	                            options: {
	                                dialogClass: 'address-invalid',
	                                width: 700,
	                                open: function() {
	                                    validator.init();
	                                    var $editAddrForm = $('body').find('#EditAddressForm');
	                                    new AddressDialog($form, $editAddrForm, formLabel).init();
	                                },
	                                close: function() {
	                                    $(this).dialog('close');
	                                    $(dlg).remove();
	                                }
	                            }
	                        });
	                        $(dlg).html('').append(form);
	                        $(dlg).dialog('open');
	                    } else {
	                    	$submitbtn.removeClass("trade-form-click");
						    $submitbtn.trigger("click");
						    $submitbtn.prop('disabled',true);
	                    }
	                }
	            });
			} else {
				$submitbtn.prop('disabled',false);
			    $submitbtn.removeClass("trade-form-click");
			    $submitbtn.trigger("click");
			    $submitbtn.prop('disabled',true);
			} 
    	});

		$('body').off('click', 'input[name$="_isSameAsBiz"]').on('click', 'input[name$="_isSameAsBiz"]', function (e) {
			var mailingaddressNode = this.closest('form').querySelector('.mailingaddressfields');
			mailingaddressNode.classList.toggle('hide', this.checked);
			setMailingAddress(mailingaddressNode, $('form').find('.businessinformation'));
			if(!this.checked) {
				mailingaddressNode.querySelectorAll('input').forEach(function (field){
					field.value='';
				});
				mailingaddressNode.querySelectorAll('input').forEach(function (field){
					$(field).trigger('blur');
				});
			}
		});
		
		$('body').off('click', 'input[name$="_isAssociate"]').on('click', 'input[name$="_isAssociate"]', function (e) {
			var associateNode = this.closest('form').querySelector('.associatecontact');
			associateNode.classList.toggle('active', this.checked);
			$('input[name$="_associatecontact_firstname"]').removeClass('firstname');
			$('input[name$="_associatecontact_lastname"]').removeClass('lastname');
			if(!this.checked) {
				associateNode.querySelectorAll('input').forEach(function (field){
					field.value='';
				});
				associateNode.querySelectorAll('input').forEach(function (field){
					$(field).trigger('blur');
				});
			}
		});

		$('body').off('focusout', 'input[name$="_associatecontact_firstname"], input[name$="_associatecontact_lastname"]').on('focusout', 'input[name$="_associatecontact_firstname"], input[name$="_associatecontact_lastname"]', function (e) {
			var form = this.closest('form');
			var identity = form.querySelector('input[name$="_associatecontact_firstname"]').value + form.querySelector('input[name$="_associatecontact_lastname"]').value;
			var emailWrapper = form.querySelector('div[class*="_associatecontact_email"]');
			setFieldRequired(emailWrapper, identity !== '')
		});
		
		$('body').off("change",'select#dwfrm_tradeprogram_businessinformation_countries_country').on("change",'select#dwfrm_tradeprogram_businessinformation_countries_country',function(){
			var countryselected = $(this).find('option:selected').val();
			if(countryselected !== 'US') {
				$curForm.find('.dwfrm_tradeprogram_businessinformation_otherstate').removeClass("hide");
				$curForm.find('.dwfrm_tradeprogram_businessinformation_states_stateCode').addClass("hide");						
	    	} else {
	    		$curForm.find('.dwfrm_tradeprogram_businessinformation_otherstate').addClass("hide");
				$curForm.find('.dwfrm_tradeprogram_businessinformation_states_stateCode').removeClass("hide");
	    	}
			if($companyRegNumberWrapper.length ){
				if(countryselected === 'GB'){
					$companyRegNumberWrapper.removeClass("hide");
					setFieldRequired($companyRegNumberWrapper[0], true);
				} else if (countryselected === 'US') {
					$companyRegNumberWrapper.removeClass("hide");
					setFieldRequired($companyRegNumberWrapper[0], false);
				} else {
					$companyRegNumberWrapper.addClass("hide");
					$companyRegNumberWrapper.find('input').val('');
					setFieldRequired($companyRegNumberWrapper[0], false);
				}
			}
		});
		$('body').off("change",'select#dwfrm_tradeprogram_mailingaddress_countries_country').on("change",'select#dwfrm_tradeprogram_mailingaddress_countries_country',function(){
			var countryselected = $(this).find('option:selected').val();
			if(countryselected !== 'US') {
				$curForm.find('.dwfrm_tradeprogram_mailingaddress_otherstate').removeClass("hide");
				$curForm.find('.dwfrm_tradeprogram_mailingaddress_states_stateCode').addClass("hide");
	    	} else {
	    		$curForm.find('.dwfrm_tradeprogram_mailingaddress_otherstate').addClass("hide");
				$curForm.find('.dwfrm_tradeprogram_mailingaddress_states_stateCode').removeClass("hide");
	    	}
		});
		$('body').off('change', 'select[name$="_selectexisting"]').on('change', 'select[name$="_selectexisting"]', function(e) {
			$('select[name$="_selectexisting"]').find('option:first').prop('disabled', true);
			$('input[name$="_existing_tradenumber"]').removeClass('exist-trade-number');
			if (this.value === 'true') {
				existing = true;
				$('.existing-trade').removeClass('hide').find('input').prop('disabled', false);
				$('.step-two').addClass('existing');
				btnState();
				$('.businessinformation, .dwfrm_tradeprogram_mailingaddress_isSameAsBiz, .additionalrequirements-wrapper, .typeofbusiness').find(':input').val('').prop('disabled', true);
			} else {
				existing = false;
				$('input[name$="_existing_tradenumber"]').val('');
				$('.existing-trade').addClass('hide').find('input').prop('disabled', true);
        		$('.step-two').removeClass('existing');
        		btnState();
				$('.businessinformation, .dwfrm_tradeprogram_mailingaddress_isSameAsBiz, .additionalrequirements-wrapper, .typeofbusiness').find(':input').prop('disabled', false);
        	}
		});
		$('body').off('click', '.trade-form-continue').on('click', '.trade-form-continue', function(e) {
    	    e.preventDefault();
    	   
    	    $curForm.validate();
	        if (!$curForm.valid()) {
	        	$('.trade-form-continue').prop('disabled', true);
	            return false;
	        }
	        $(this).addClass('hide');
    	    if ($('select[name$="_selectexisting"]').val() === 'true') {
				$('.step-two').addClass('existing').removeClass('hide');
				$('input[name$="_existing_tradenumber"]').addClass('exist-trade-number');
			} else if ($('select[name$="_selectexisting"]').val() === 'false') {
				$('.step-two').removeClass('existing').removeClass('hide');
			}
		});

        $('body').off('click', '.trade-terms').on('click', '.trade-terms', function(e) {
            e.preventDefault();
            e.stopPropagation();
            var terms = $('.trade-terms-content').clone();

            if ($(window).width() <= 767) {
                toaster.teleport($(terms), 'bottom', null, 'full-height trade-terms-container')
                    .then();
            } else {
                toaster.teleport($(terms), 'right', null, 'full-height trade-terms-container')
                    .then();
        	}
	    });


		var buisnessselction = function () {
			if($('.typeofbusiness-checkboxes .input-checkbox:checked').length == 0) {
				$('.typeofbusiness-checkboxes').addClass('required');
	        } else {
	        	$('.typeofbusiness-checkboxes').removeClass('required');
	        }
		}
		
        setTimeout(function() {
		    buisnessselction();
        },1000);
        
		$('.typeofbusiness-checkboxes .input-checkbox').click(function() {
			var $this = $(this).next().text();
		    buisnessselction();
		 	$('.typeofbusiness span').removeClass("error");
        	$('.typeofbusiness span').addClass("hide");
		});
		
		$('.professionalorganization-checkboxes .input-checkbox').click(function(){
			var $this = $(this).next().text();
		    $('.professionalorganization-checkboxes .input-checkbox').each(function(){
		    	if($this !== $(this).next().text()) {
		    		$(this).prop('checked', false); 
		    	}
		    }); 
		});
		
		$('input[name$=_firstName],input[name$=_lastName]').off('focusout').on('focusout', function() {
		    $(this).removeClass('firstName');
		    $(this).removeClass('lastName');
		});
		
		$('input[name$=_tradenumber]').off('blur keyup').on('blur keyup', function() {
			var error = $('.existing-trade').find('input').next('#dwfrm_tradeprogram_existing_tradenumber-error');
			var regXSpecialChar = /^[a-zA-Z0-9\-]+$/;
			var tradenumber = $(this).val();
		    var isValid = regXSpecialChar.test(tradenumber);
			if (isValid && tradenumber.length < 18) {
				error.html(Resources.TRADE_NUMBER_MINLENGTH);
				$('.trade-form-continue').prop('disabled', true);
			} else {
				$('.trade-form-continue').prop('disabled', false);
			}
		});

		$('.termsofmembership .input-checkbox').click(function(){
			btnState();
		});

        countdownTimer();

		$('body').off('click', '.session-continue').on('click', '.session-continue', function(e) {
            e.preventDefault();
			e.stopPropagation();
			continueSession();
		});

		$('body').off('click', '.certsolv').on('click', '.certsolv', function(e) {
            e.preventDefault();
			e.stopPropagation();
			var url = $(this).attr('action');
			return $.ajax({
				type: 'POST',
				async: true,
				dataType: 'json',
                contentType: 'application/json',
				url: url, 
				success: function (data) {
					if (data.status == 'OK') {
						window.open(data.redirecturl, '_blank');
					} else if (data.status == 'loggedout') {
                        window.location.href = data.redirecturl;
				    } else {
                        var error = data.status == 'tradeNoError' ?  Resources.CERTSOLVTRADENOERR : Resources.CERTSOLVFAIL 
						$(document).find('.certsolv-fail-action').attr("style","display:block");
						$(document).find('.certsolv-fail-action').text(error);
					} 
				}
			});
		});	
    }	
};

module.exports = tradeuser;