'use strict';

function couponCode() {
    var couponCodeButton = $('.promo-code-accordion .apply');
    var couponCodeInput = $('.promo-code-accordion .input-text');

    couponCodeInput.on('keyup focusout paste', function (event) {
    	var coupon = this;
    	setTimeout(function(e) {
    		if(!$(coupon).val() == '') {
                couponCodeButton.prop('disabled', false);
            } else {
                couponCodeButton.prop('disabled', true);
                if($('.coupon.error').length > 0 && $('.coupon.error').text().length>0){
            		$('.coupon.error').remove();
            	}
            }
    	}, 0);
        if (event.keyCode === 13) {
        	couponCodeButton.click(); 
        }
    });

}
    
function promoToggle(openPromoToggle) {
    var openPromoToggle;
	
	if($('#success-coupon-code').length != 0 || openPromoToggle) {
		$('.promo-code-accordion').show();
	} else {
		$('.promo-code-accordion').hide();
	}
}

exports.init = function () {
    couponCode();
};

exports.couponCode = couponCode;
exports.promoToggle = promoToggle;