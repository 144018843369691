'use strict';

var ajax = require('./ajax'),
    util = require('./util');

exports.init = function init() {
    var phoneInput = $('input[name$=_addressFields_phone]');
    if (phoneInput !== null && phoneInput.length > 0 && !phoneInput.length > 15) {
        var phoneInputValue = phoneInput.val();

        phoneInput.intlTelInput({
            nationalMode: false,
            formatOnDisplay: true,
            autoHideDialCode: false,
            utilsScript: Urls.staticPath +'lib/jquery/utils.js'
        });
        if (phoneInputValue.length > 2) {
            phoneInput.val(phoneInputValue);
        } else {
            phoneInput.val('');
        }

        phoneInput.after(phoneInput.parent().siblings('label').clone());
        phoneInput.parent().siblings('label').remove();
    }

    // Disables addressy for account login page on order tracking.
    if ($('.pt_account').find('[id$="_ordertrack"]').length > 0) {
        $('.pt_account').siblings('.pca').remove();
    }

    // Current password form - Clear error when user starts typing current password.
    if ($('input[id*="_login_currentpassword_"]') !== null && $('input[id*="_login_currentpassword_"]').length > 0) {
        $('input[id*="_login_currentpassword_"]').on('keyup', function () {
            if ($(this).val().length > 0) {
                $('div[class*="_login_currentpassword_"]').find('.error-message').remove();
            }
        });
    }

    // Current password form - Clear error when user starts typing new password.
    if ($('input[id*="_login_newpasswordconfirm_"]') !== null && $('input[id*="_login_newpasswordconfirm_"]').length > 0) {
        $('input[id*="_login_newpasswordconfirm_"]').on('keyup', function () {
            if ($(this).val().length > 0) {
                $('div[class*="_login_newpasswordconfirm_"]').find('.error-message').remove();
            }
        });
    }

    $('.input-text, .input-textarea').each(function() {
        var $this = $(this);
        if ($this.val() === null || $this.val().length === 0) {
            $this.addClass('empty');
        } else {
            $this.removeClass('empty');
        }
    });

    $('input[maxlength], textarea[maxlength]').on('keyup', function (event) {
        var $this = $(this);
        var maxLength = parseInt($this.attr('maxlength'));
        if ($this.val().length >= maxLength) {
            $this.val($this.val().substring(0, maxLength));
        }
    });

    //A workaround for IE-11 because :placeholder-shown dose not work on IE-11
    $('.input-text, .input-textarea').on('change blur input', function() {
        var $this = $(this);
        if ($this.val() == null || $this.val().length == 0) {
            $this.addClass('empty');
        } else {
            $this.removeClass('empty');
        }
    });

    if (!$('.checkoutmultishipping').length) {
        $('select').each(function() {
            if ($(this).find('option:selected').val() === null || $(this).find('option:selected').val() === '') {
                $(this).removeClass('valid');
            } else {
                $(this).addClass('valid');
            }
        });

        $('select').on('change', function() {
            if ($(this).find('option:selected').val() === null || $(this).find('option:selected').val() === '') {
                $(this).removeClass('valid');
            } else {
                $(this).addClass('valid');
            }
        });
    }

    // check if email exist
    $(".input-text[type='email']").on('focusout, blur', function() {
        var email = $(this).val();
        var $el = $(this);
        if (email != '' && $(this).hasClass('valid')) {
            $el.parents('form').find('.login-message').addClass('hide');
            $el.parents('form').find('.register-message').addClass('hide');

            $el.parents('.has-float-label').find('span.error').hide();

            var url = util.appendParamsToUrl(Urls.checkIfEmailExists, {
                emailValue : email
            });

            ajax.load({
                url: url,
                type: 'GET',
                dataType: 'json',
                contentType: 'application/json',
                async: false,
                callback: function(data) {
                    var response = $.parseJSON(data);
                    if(response.status && $el.parents('form').hasClass('register-form')) {
                        $el.parents('form').find('.login-message').removeClass('hide');
                    } else if (!response.status && ($el.parents('form').hasClass('login-form') || $el.parents('form').hasClass('password-reset-form'))) {
                        $el.parents('form').find('.register-message').removeClass('hide');
                    } else {
                        $el.parents('form').find('.login-message').addClass('hide');
                        $el.parents('form').find('.register-message').addClass('hide');
                    }
                }
            });
        } else if ($el.hasClass('error')) {
            $el.parents('form').find('.login-message, .register-message').addClass('hide');
        }
    });

    $('.register-message a').on('click', function(e) {
        e.preventDefault();
        $(this).parents('.account-login-register').find('#createlogin-tab').trigger('click');
    });

    $('.login-message a').on('click', function(e) {
        e.preventDefault();
        $(this).parents('.account-login-register').find('#login-tab').trigger('click')
    });
};
