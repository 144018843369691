
function OneTrustPopUp() {
    var status;

    this.init = function() {
        $(document).on('click', '.ot-sdk-show-settings', updateCheckbox);
        $(document).on('click', '.category-switch-handler', updateCheckbox);
    };

    function updateCheckbox(e) {
        var checkbox = $('.category-switch-handler');
        var container = checkbox.parents('.checkbox');

        container.find('.rl-checkbox-status').remove();

        if (!status) {
            status = $('<span>').insertBefore(checkbox);
        }

        status.text(checkbox.is(':checked') ? 'ON' : 'OFF');
    }
}

module.exports = OneTrustPopUp;