'use strict';

function centerLoader() {
    $('.loader').each(function() {
        var $indic = $(this).find('.loader-indicator');
        if ($indic && $indic.css('position') != 'fixed') {
            var bound = this.getBoundingClientRect();
            var wH = $(window).height();
            if (bound.height > 1.5 * $indic.height()) {
                var h = Math.max(0, bound.top > 0 ? Math.min(bound.height, wH - bound.top) : Math.min(bound.bottom, wH));
                if (h> $indic.height()){
                    $indic.css('top', bound.top > 0 ?  h / 2 : -bound.top + h / 2);
                } else {
                    $indic.css('top', '50%');
                }
                
            }
        }
    })
}

function track(){
    if ($('.loader').length == 0){
        $(window).off('scroll', centerLoader);
    } else {
        if($('.loader').length == 1){
            $(window).on('scroll', centerLoader);
        }
        centerLoader();
    }
}


/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
 */
var show = function(container, noIndicator, clickThru) {
    var target = (!container || $(container).length === 0) ? $('body') : $(container);
    var $loader = target.find('.loader');
    if ($loader.length == 0) {
        target.addClass('has-loader')
        $loader = $('<div/>').addClass('loader ' + (noIndicator ? 'noIndicator' : ''));
        if (!noIndicator) {
            $loader.append($('<div/>').addClass('loader-indicator'));
        } else {

        }
        $loader.append($('<div/>').addClass('loader-bg'));
        if (!clickThru) {
            $loader.appendTo(target);
        }
    } else {
        if (!clickThru) {
            $loader.remove();
            return null;
        }
        if (!noIndicator) {
            $loader.remove('.loader-indicator');
        }

    }

    if ($loader) {
        if (target[0].scrollHeight > target.height()) {
            $loader.css('height', target[0].scrollHeight);
        } else {
            $loader.css('height', '');
        }
        $loader.show();
    }
    track();
    return $loader;
};
/**
 * @function
 * @description Hides an AJAX-loader
 */
var hide = function(container) {
    var target = (!container || $(container).length === 0) ? $('body') : $(container);
    target.find('.loader').remove();
    target.removeClass('has-loader');
    track();
};

exports.show = show;
exports.hide = hide;
